import { CovChart } from '@components/chartTemplates/CovChart';
import { EnergyChart } from '@components/chartTemplates/EnergyChart';
import { HumidityChart } from '@components/chartTemplates/HumidityChart';
import { OtherMeasureChart } from '@components/chartTemplates/OtherMeasureChart';
import { PresenceChart } from '@components/chartTemplates/PresenceChart';
import Loading from '@components/loading/Loading';
import { batiment } from '@tsTypes/mySitesTypes';
import { sensor } from '@tsTypes/sensorType';
import { formatDistanceToNow, subHours } from 'date-fns';
import React, { useMemo, useRef } from 'react';
import HighchartsBoost from 'highcharts/modules/boost';
import HighchartsReact, { HighchartsReactRefObject } from 'highcharts-react-official';
import Highcharts from 'highcharts';
import { formatChartDatas } from '@utils/chartFunctions';
import { useDataFetch } from '@hooks/useDataFetch';
import useSWR from 'swr';
import { GetFetcher } from '@api/SwrFetcher';
import { fr } from 'date-fns/locale';

interface props {
	currentSensor: sensor;
	currentBatiment: batiment;
}

//modèle de graphique en fonction du type de capteur
const chartModel = {
	électricité: EnergyChart,
	gaz: EnergyChart,
	température: OtherMeasureChart,
	humidité: HumidityChart,
	CO2: OtherMeasureChart,
	COV: CovChart,
	'différence de pression': OtherMeasureChart,
	luminosité: OtherMeasureChart,
	pression: OtherMeasureChart,
	présence: PresenceChart,
};

export default function ChartDashboardBatiment({ currentSensor, currentBatiment }: props) {
	HighchartsBoost(Highcharts);

	const chartRef = useRef<HighchartsReactRefObject>(null);

	const { data: maxDate } = useSWR(
		{
			url: '/sensor/get-min-max-mesures',
			params: {
				idSensor: currentSensor.id_compteur,
				type: currentSensor.type_energie,
			},
		},
		GetFetcher,
		{ revalidateOnFocus: false }
	);

	//on récupère les données des mesures
	const { data, isLoading } = useDataFetch('/sensor/get-mesures', {
		idSensor: currentSensor.id_compteur,
		type: currentSensor.type_energie,
		endDate: maxDate?.max?.timestamp,
		startDate: subHours(new Date(maxDate?.max?.timestamp * 1000), 24)
			.getTime()
			.toString()
			.slice(0, -3),
	});

	//on formate les données pour les afficher dans le graphique
	//eslint-disable-next-line
	const mesure = useMemo(
		() => formatChartDatas(data?.mesures, currentSensor),
		[data, currentSensor]
	);

	if (isLoading || !currentBatiment) return <Loading />;

	return (
		<div id="chart-dashboard-batiment">
			{mesure.mesure.length > 0 && maxDate?.max?.timestamp ? (
				<>
					<HighchartsReact
						highcharts={Highcharts}
						options={chartModel[currentSensor.type_energie](
							currentSensor,
							currentBatiment,
							mesure.mesure,
							mesure.index,
							[],
							null,
							true
						)}
						ref={chartRef}
					/>
					<p id="time-ref">
						Dernière mesure{' '}
						{formatDistanceToNow(new Date(maxDate?.max.timestamp * 1000), {
							addSuffix: true,
							locale: fr,
						})}
					</p>
				</>
			) : (
				<h1>Pas de données ces dernières 24h</h1>
			)}
		</div>
	);
}
