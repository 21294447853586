import { Button, Menu, MenuButton, MenuList } from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from "react";
import { Calendar } from "react-date-range";
import "./addManualMeasure.scss";
import { CalibrationContext } from "@hooks/useCalibrationContext";
import { fr } from "date-fns/locale";
import fetchApi from "@api/fetchApi";

const PCI = 1.12;

const AddManualMeasure = () => {
    const {
        manualMeasure,
        setManualMeasure,
        currentSensor,
        date,
        indexDivider,
        realIndex,
        setRealIndex,
        estimateIndex,
        setEstimateIndex
    } = useContext(CalibrationContext);

    //date rentrée dans le composant calendrier
    const [dateCalendar, setDateCalendar] = useState(date.endDate);

    //état du menu contenant le calendrier
    const [isDateMenuOpen, setIsDateMenuOpen] = useState(false);

    //fonction trigger à l'envoi du formulaire qui va inscrire la mesure manuelle dans le state et en base de données
    const handleForm = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const form = e.currentTarget;

        //on récupère les valeurs du champ time du formulaire
        const hour = parseInt(form.time.value.split(":")[0]);
        const minute = parseInt(form.time.value.split(":")[1]);

        //on set les heures et les minutes à 0
        dateCalendar.setHours(hour);
        dateCalendar.setMinutes(minute);
        dateCalendar.setSeconds(0);

        //on récupère le décalage horaire de la date choisi dans le calendar
        let UTC = Math.abs(dateCalendar.getTimezoneOffset()) * 60 * 1000;
        
        if(form.unite.value === "estime") {

            //si une mesure est déjà présente à ce timestamp on la supprime
            let temp = estimateIndex.filter(
                (measure) => measure[0] !== dateCalendar.getTime() + UTC
            );

            //valeur de l'index estimé en Wh
            let newValue = parseFloat(form.measure.value);

            //si le capteur courrant mesure en m3 on convertie la valeur en m3
            if(currentSensor.unite === "m3") {
                newValue = Math.round(newValue / PCI);
            }

            //on ajoute la nouvelle mesure dans la liste temporaire qui contient toutes le indexs estimés
            temp.push([
                dateCalendar.getTime() + UTC,
                Math.round(newValue),
            ]);

            //on ajoute l'index estimé dans le state
            setEstimateIndex(temp);

            //on envoie l'index estimé en base de données
            fetchApi.post(`/estimate-index/add`, {
                sensorId: currentSensor.id_compteur,
                index: Math.round(newValue),
                timestamp: dateCalendar.getTime().toString().slice(0, 10)
            })  

        } else if (form.unite.value !== "reel") {
            //si une mesure est déjà présente à ce timestamp on la supprime
            let temp = manualMeasure.filter(
                (measure) => measure[0] !== dateCalendar.getTime() + UTC
            );

            let newValue = parseFloat(form.measure.value);

            if (currentSensor.unite === "Wh" && currentSensor.type_energie === "gaz") {
                newValue = Math.round(newValue / PCI);
            }

            //on ajoute la nouvelle mesure dans la liste temporaire qui contient toutes les mesures manuelles
            temp.push([
                dateCalendar.getTime() + UTC,
                Math.round(newValue / indexDivider),
            ]);

            //on ajoute la mesure manuelle dans le state
            setManualMeasure(temp);

            //on envoie la mesure manuelle en base de données
            fetchApi.post(`/manual-measure/add`, {
                sensorId: currentSensor.id_compteur,
                valeur: newValue,
                timestamp: dateCalendar.getTime().toString().slice(0, 10),
            });
        } else {
            //si le type de mesure est un index réel
            //si une mesure est déjà présente à ce timestamp on la supprime
            let temp = realIndex.filter(
                (measure) => measure[0] !== dateCalendar.getTime() + UTC
            );

            //valeur de l'index réel en Wh
            let newValue = parseFloat(form.measure.value);

            //si le capteur courrant mesure en m3 on convertie la valeur en m3
            if (currentSensor.unite === "m3") {
                newValue = Math.round(newValue / PCI);
            }

            //on ajoute la nouvelle mesure dans la liste temporaire qui contient toutes les mesures manuelles
            temp.push([dateCalendar.getTime() + UTC, Math.round(newValue)]);

            //on ajoute la mesure manuelle dans le state
            setRealIndex(temp);

            //on envoie l'index réel en base de données
            fetchApi.post(`/real-index/add`, {
                sensorId: currentSensor.id_compteur,
                index: Math.round(newValue),
                timestamp: dateCalendar.getTime().toString().slice(0, 10),
            });
        }
    };
    useEffect(() => {
        //on met à jour la date du calendar
        setDateCalendar(date.endDate);
    }, [date.endDate]);

    return (
        <form
            className="addManualMeasure"
            onSubmit={handleForm}
        >
            <div>
                <label htmlFor="measure">Mesure :</label>
                <input
                    type="number"
                    name="measure"
                    id="measure"
                    min="0"
                    required
                />
                <select
                    name="unite"
                    id="unite"
                >
                    <option disabled={currentSensor.type_energie === "gaz"} value="Wh">Wh</option>
                    <option
                        disabled={currentSensor.type_energie === "électricité"}
                        value="m3"
                    >
                        m3
                    </option>
                    <option value="reel">Index de facturation (kWh)</option>
                    <option value="estime">Index de facturation estimé (kWh)</option>
                </select>
            </div>
            <div>
                <label htmlFor="date">Date :</label>
                <Menu
                    isOpen={isDateMenuOpen}
                    onOpen={() => setIsDateMenuOpen(true)}
                    onClose={() => setIsDateMenuOpen(false)}
                >
                    <MenuButton as={Button}>
                        {dateCalendar.toLocaleDateString("fr")}
                    </MenuButton>
                    <MenuList>
                        <Calendar
                            maxDate={new Date()}
                            minDate={date.startDate}
                            date={dateCalendar}
                            onChange={(item) => {
                                setDateCalendar(item);
                                setIsDateMenuOpen(false);
                            }}
                            color="#225522"
                            dateDisplayFormat="dd/MMM/yyyy"
                            locale={fr}
                        />
                    </MenuList>
                </Menu>
            </div>
            <div>
                <label htmlFor="time">Heure : </label>
                <input
                    type="time"
                    name="time"
                    id="time"
                    required
                />
            </div>
            <input
                type="submit"
                value="Ajouter la mesure"
            />
        </form>
    );
};

export default AddManualMeasure;
