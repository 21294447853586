import React, { useEffect } from "react";
import Header from "@components/header/Header";
import NavBarLogged from "@components/navBarLogged/NavbarLogged";
import MyMap from "@pages/private/user/mapPage/components/myMap/MyMap";
import "./mapPage.scss";
import { sendGAPageView } from "@api/analytics";

export default function MapPage() {

    document.title = "kWh50 - Carte";

    useEffect(() => {
        sendGAPageView(
            "Carte",
            window
        );
    }, []);

    return (
        <div className="mapPage">
            <Header title="Ma carte" />
            <NavBarLogged />
            <MyMap />
        </div>
    );
}
