import Loading from '@components/loading/Loading';
import { sensor } from '@tsTypes/sensorType';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import HighchartsBoost from 'highcharts/modules/boost';
import React, { useMemo } from 'react';
import './myBatimentChart.scss';
import { EnergyChart } from '@components/chartTemplates/EnergyChart';
import { batiment } from '@tsTypes/mySitesTypes';
import { HumidityChart } from '@components/chartTemplates/HumidityChart';
import { useDataFetch } from '@hooks/useDataFetch';
import { formatChartDatas } from '@utils/chartFunctions';
import { usePlotbands } from '@hooks/usePlotbands';
import { CovChart } from '@components/chartTemplates/CovChart';
import { PresenceChart } from '@components/chartTemplates/PresenceChart';
import { OtherMeasureChart } from '@components/chartTemplates/OtherMeasureChart';
import { useToast } from '@chakra-ui/react';
import { TemperatureChart } from '@components/chartTemplates/TemperatureChart';

interface props {
	currentSensor: sensor;
	currentDate: {
		startDate: Date;
		endDate: Date;
	};
	currentBatiment: batiment;
}

//modèle de graphique en fonction du type de capteur
const chartModel = {
	électricité: EnergyChart,
	gaz: EnergyChart,
	température: TemperatureChart,
	humidité: HumidityChart,
	CO2: OtherMeasureChart,
	COV: CovChart,
	'différence de pression': OtherMeasureChart,
	luminosité: OtherMeasureChart,
	pression: OtherMeasureChart,
	présence: PresenceChart,
	intensité: OtherMeasureChart,
};

export default function MyBatimentChart({ currentSensor, currentDate, currentBatiment }: props) {
	HighchartsBoost(Highcharts);

	//on récupère les données des mesures
	const { data, isLoading } = useDataFetch('/sensor/get-mesures', {
		idSensor: currentSensor.id_compteur,
		type: currentSensor.type_energie,
		startDate: currentDate.startDate.getTime().toString().slice(0, -3),
		endDate: currentDate.endDate.getTime().toString().slice(0, -3),
	});

	//on formate les données pour les afficher dans le graphique
	//eslint-disable-next-line
	const mesure = useMemo(
		() => formatChartDatas(data?.mesures, currentSensor),
		[data, currentSensor]
	);

	//on récupère les plotbands
	const plotbands = usePlotbands(
		currentDate.startDate.getTime() - currentDate.startDate.getTimezoneOffset() * 60000,
		currentDate.endDate.getTime() - currentDate.endDate.getTimezoneOffset() * 60000,
		currentBatiment
	);

	//initialisation du toast pour plus de feedback lors du switch des unités
	const toast = useToast();

	if (isLoading || !data) return <Loading />;

	return (
		<div id="chart-batiment" className="highcharts-default">
			{data && data.mesures.length > 0 ? (
				<HighchartsReact
					highcharts={Highcharts}
					options={chartModel[currentSensor.type_energie](
						currentSensor,
						currentBatiment,
						mesure.mesure,
						mesure.index,
						plotbands,
						toast
					)}
					//constructorType={"stockChart"}
				/>
			) : (
				<h1>Aucunes données pour la période sélectionée</h1>
			)}
		</div>
	);
}
