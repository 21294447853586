import fetchApi from "@api/fetchApi";
import React from "react";
import { useNavigate } from "react-router-dom";
import "./logoutButton.scss";
import { useLoginStore } from "@store/loginStore";

const LogoutButton = () => {
    const navigate = useNavigate();
    const { logout } = useLoginStore();

    //appel au endPoint logout afin de supprimer le cookie avec le token de connection
    const handleLogout = () => {

        fetchApi.get("/login/logout").finally(() => {
            logout();
            navigate("/");
        });
    };

    return (
        <button
            className="logoutButton"
            onClick={handleLogout}
        >
            Se déconnecter
        </button>
    );
};

export default LogoutButton;
