import React, { useEffect, useState } from "react";
import logoDesktopBlanc from "@assets/img/logo/kWh50-Logo_Blanctransparent.png";
import logoDesktopCouleur from "@assets/img/logo/kWh50-Logo_Couleursfond blanc.png";
import "./navBar.scss";
import { Link } from "react-router-dom";
import {
    Button,
    Drawer,
    DrawerBody,
    DrawerCloseButton,
    DrawerContent,
    DrawerHeader,
    DrawerOverlay,
    Flex,
    Image,
    useDisclosure,
} from "@chakra-ui/react";
import { IoMenu } from "react-icons/io5";
import { useLoginStore } from "@store/loginStore";

const NavBar = () => {
    const [links, setLinks] = useState([
        { name: "Kwh50", path: "/", type: "link" },
        { name: "Contact", path: "/", type: "link" },
        { name: "Connexion", path: "/login", type: "button" },
        { name: "Démo", path: "/login", type: "button" },
    ]);

    const linksLogged = [
        { name: "Kwh50", path: "/", type: "link" },
        { name: "Contact", path: "/", type: "link" },
        { name: "Mes bâtiments", path: "/user/mes-sites", type: "button" },
        { name: "Déconnexion", path: "/user/logout", type: "button" },
    ];

    const [isOnTop, setIsOnTop] = React.useState(true);
    const { onOpen, isOpen, onClose } = useDisclosure();
    const { isLogin } = useLoginStore();

    useEffect(() => {

        if (isLogin) {
            setLinks(linksLogged)
        }

        window.addEventListener("scroll", () => {
            if (window.scrollY > 0) {
                setIsOnTop(false);
            } else {
                setIsOnTop(true);
            }
        });
        // eslint-disable-next-line
    }, []);

    return (
        <>
            <div
                id="navBar"
                className={isOnTop ? "" : "scrolled-nav"}
            >
                <nav>
                    <Link to={"/"}>
                        <img
                            src={
                                isOnTop ? logoDesktopBlanc : logoDesktopCouleur
                            }
                            alt="Logo Kilowattheure50"
                        />
                    </Link>
                    <ul>
                        {links.map((link, index) => {
                            return (
                                <li key={index}>
                                    <Link
                                        to={link.path}
                                        className={link.type}
                                    >
                                        {link.name}
                                    </Link>
                                </li>
                            );
                        })}
                    </ul>
                    <Button
                        className="btn-menu-mobile"
                        onClick={onOpen}
                        backgroundColor="transparent"
                        _hover={{ backgroundColor: "transparent" }}
                    >
                        <IoMenu
                            size={40}
                            color={isOnTop ? "white" : "#117166"}
                        />
                    </Button>
                    <Drawer
                        placement="right"
                        onClose={onClose}
                        isOpen={isOpen}
                        size="xs"
                    >
                        <DrawerOverlay />
                        <DrawerContent className="welcome-menu-drawer">
                            <DrawerCloseButton className="closeButton" />
                            <DrawerHeader>
                                <Flex
                                    marginLeft="5"
                                    marginRight="5"
                                >
                                    <Image
                                        src={logoDesktopCouleur}
                                        alt="Logo Kilowattheure50"
                                    />
                                </Flex>
                            </DrawerHeader>
                            <DrawerBody>
                                <div className="list-link-mobile">
                                    <Flex
                                        direction="column"
                                        align="center"
                                        justify="center"
                                        gap="10"
                                        marginTop="12"
                                    >
                                        {links.map((link, index) => {
                                            return (
                                                <Link
                                                    to={link.path}
                                                    className={link.type}
                                                    key={index}
                                                >
                                                    {link.name}
                                                </Link>
                                            );
                                        })}
                                    </Flex>
                                </div>
                            </DrawerBody>
                        </DrawerContent>
                    </Drawer>
                </nav>
            </div>
        </>
    );
};

export default NavBar;
