import React, { useEffect, useState } from "react";
import NavBarLogged from "@components/navBarLogged/NavbarLogged";
import Header from "@components/header/Header";
import { useLocation, useNavigate } from "react-router-dom";
import { CalibrationContext } from "@hooks/useCalibrationContext";
import ChartCalibration from "@pages/private/user/calibrationPage/components/chartCalibration/ChartCalibration";
import "./calibrationPage.scss";
import { _getManualMeasure } from "@utils/manualMeasureChartFunctions";
import { _requestGetMesures } from "@utils/requestGetMesures";
import { applyDataUnits } from "@utils/chartFunctions";
import { sensor } from "@tsTypes/sensorType";
import fetchApi from "@api/fetchApi";
import { sendGAPageView } from "@api/analytics";

const CalibrationPage = () => {
    document.title = "kWh50 - Étalonnage";

    const { state } = useLocation();

    const navigate = useNavigate();

    //tab qui était ouverte sur la page mes batiments
    const [currentTab, setCurrentTab] = useState(
        state ? state.currentTab : null
    );

    //capteur actuel sur lequel porte l'étalonnage
    const [currentSensor, setCurrentSensor] = useState<sensor | null>(
        state ? state.currentSensor : null
    );

    //tableau des mesures manuelles
    const [manualMeasure, setManualMeasure] = useState<[number, number][]>([]);

    //tableau de l'index
    const [index, setIndex] = useState<[number, number][]>([]);

    //date de début et de fin du graphique sert aussi de borne pour le composant Calendrier : startDate = date de la première mesure manuelle (faite par nous), endDate = date du jour
    const [date, setDate] = useState({
        startDate: new Date(),
        endDate: new Date(),
    });

    //diviseur de l'unité (exemple : kilo = 1 000, mega = 1 000 000 ....)
    const [indexDivider, setIndexDivider] = useState(1);

    //préfix de l'unité (exemple : k (kilo), M (méga))
    const [prefixUnit, setPrefixUnit] = useState("");

    //tableau des relevés réels d'index
    const [realIndex, setRealIndex] = useState<[number, number][]>([]);

    //tableau des relevés estimés d'index
    const [estimateIndex, setEstimateIndex] = useState<[number, number][]>([]);

    //si aucun state on renvoi sur la page mes-sites
    useEffect(() => {
        if (state == null) {
            navigate("/user/mes-sites");
        }

        sendGAPageView("Page d'étalonnage", window);

        // eslint-disable-next-line
    }, []);

    //on récupère les mesures manuelles
    useEffect(() => {
        //fonction pour récupérer les mesures manuelles
        const requestManualMeasure = async (): Promise<void> => {
            let manualMeasure: [number, number][] = await _getManualMeasure(
                currentSensor
            );

            //si on a une seule mesure manuelle on change la début pour le calendrier soit bien borné et que l'user puisse saisir une mesure
            if (manualMeasure.length === 1) {
                let UTC = Math.abs(
                    new Date(manualMeasure[0][0] * 1000).getTimezoneOffset()
                );

                //on ajoute le décalage horaire à la date
                manualMeasure[0][0] = (manualMeasure[0][0] + UTC * 60) * 1000;

                //on définit les dates de début et de fin
                let date = {
                    //première date de relevé manuel faite par nous
                    startDate: new Date(manualMeasure[0][0]),
                    //dernière date de relevé manuel
                    endDate: new Date(),
                };

                setDate(date);
            }

            //si on a assez de mesures manuelles on peut traiter les données
            if (manualMeasure.length > 1) {
                //on formate les mesures pour les afficher dans le graphique
                manualMeasure = manualMeasure.map(
                    (mesure: [number, number]) => {
                        //on récupère le décalage horaire
                        let UTC = Math.abs(
                            new Date(mesure[0] * 1000).getTimezoneOffset()
                        );

                        //on ajoute le décalage horaire à la date
                        mesure[0] = mesure[0] + UTC * 60;

                        return [mesure[0] * 1000, mesure[1]];
                    }
                );

                //on définit les dates de début et de fin
                let date = {
                    //première date de relevé manuel faite par nous
                    startDate: new Date(manualMeasure[0][0]),
                    //dernière date de relevé manuel
                    endDate: new Date(
                        manualMeasure[manualMeasure.length - 1][0]
                    ),
                };

                //on récupère les mesures instantanées comprises entre le début et la fin des mesures manuelles
                let mesures = await _requestGetMesures(
                    currentSensor,
                    date.startDate.getTime().toString().slice(0, 10),
                    date.endDate.getTime().toString().slice(0, 10)
                );

                //on set la première mesure de l'index avec la première mesure manuelle
                let index = [manualMeasure[0]];

                //on parcours les mesures pour calculer l'index
                for (let i = 1; i < mesures.mesures.length; i++) {
                    index.push([
                        mesures.mesures[i].timestamp * 1000,
                        mesures.mesures[i].valeur + index[i - 1][1],
                    ]);
                }

                if (currentSensor.unite === "Wh") {
                    let {
                        index: newIndex,
                        indexDivider,
                        indexUnit,
                    } = applyDataUnits([], currentSensor, index);

                    //on applique les unités aux mesures manuelles, les unités sont déjà appliquées à l'index avec la fonction applyDataUnits
                    manualMeasure = manualMeasure.map(
                        (mesure: [number, number]) => [
                            mesure[0],
                            mesure[1] / indexDivider,
                        ]
                    );

                    //on set le state du préfix de l'unité
                    setPrefixUnit(indexUnit);

                    //on set l'état de l'index
                    setIndex(newIndex);

                    //on set l'état du diviseur de l'unité
                    setIndexDivider(indexDivider);
                } else {
                    //si le capteur est un compteur de gaz

                    setIndex(index);
                    setPrefixUnit("");
                    setIndexDivider(1);
                }

                //on met à jour la date
                setDate({
                    startDate: new Date(manualMeasure[0][0]),
                    endDate: new Date(
                        manualMeasure[manualMeasure.length - 1][0]
                    ),
                });
            }

            //on met à jour le contexte
            setManualMeasure(manualMeasure);
        };

        //fonction pour récupérer les mesures réelles
        const requestRealMesure = async (): Promise<void> => {
            let mesures = await fetchApi.post(`/real-index/get`, {
                sensorId: currentSensor.id_compteur,
            });

            let realIndex = mesures.data.map(
                (mesure: { timestamp: string; valeur: string }) => {
                    let UTC =
                        Math.abs(
                            new Date(
                                parseInt(mesure.timestamp) * 1000
                            ).getTimezoneOffset()
                        ) * 60;
                    return [
                        (parseInt(mesure.timestamp) + UTC) * 1000,
                        parseInt(mesure.valeur),
                    ];
                }
            );

            setRealIndex(realIndex);
        };

        //fonction pour récupérer les index estimés
        const requestEstimateIndex = async (): Promise<void> => {
            let mesures = await fetchApi.post(`/estimate-index/get`, {
                sensorId: currentSensor.id_compteur,
            });

            let estimateIndex = mesures.data.map(
                (mesure: { timestamp: string; valeur: string }) => {
                    let UTC =
                        Math.abs(
                            new Date(
                                parseInt(mesure.timestamp) * 1000
                            ).getTimezoneOffset()
                        ) * 60;

                    return [
                        (parseInt(mesure.timestamp) + UTC) * 1000,
                        parseInt(mesure.valeur),
                    ];
                }
            );

            setEstimateIndex(estimateIndex);
        };

        //une fois que la page est chargée on stop le chargement
        requestManualMeasure();
        requestRealMesure();
        requestEstimateIndex();

        // eslint-disable-next-line
    }, []);

    //useEffect trigger à chaque ajout de nouvelle mesure manuelle
    useEffect(() => {
        //fonction pour récupérer les mesures
        const requestMesures = async (): Promise<void> => {
            //date de fin de la dernière mesure manuelle avec l'UTC appliqué
            let endDateUTC =
                date.endDate.getTime() +
                1000 * Math.abs(date.endDate.getTimezoneOffset() * 60);

            //si la nouvelle mesure est plus récente que la dernière mesure manuelle on met à jour la date de fin et on récupère les mesures
            if (manualMeasure[manualMeasure.length - 1][0] >= endDateUTC) {
                //UTC de la dernière mesure de l'index
                let lastIndexUTC =
                    1000 *
                    Math.abs(
                        new Date(
                            index[index.length - 1][0]
                        ).getTimezoneOffset() * 60
                    );

                //UTC de la dernière mesure manuelle
                let lastManualMeasureUTC =
                    1000 *
                    Math.abs(
                        new Date(
                            manualMeasure[manualMeasure.length - 1][0]
                        ).getTimezoneOffset() * 60
                    );

                //on requete les mesures de la dernière date de l'index à la nouvelle date de fin
                let mesures = await _requestGetMesures(
                    currentSensor,
                    (index[index.length - 1][0] - lastIndexUTC)
                        .toString()
                        .slice(0, 10),
                    (
                        manualMeasure[manualMeasure.length - 1][0] -
                        lastManualMeasureUTC
                    )
                        .toString()
                        .slice(0, 10)
                );

                //on récupère la dernière mesure de l'index et on s'en sert comment valeur de départ pour la nouvelle partie de l'index
                let newIndex = [index[index.length - 1]];

                //on parcours toutes les nouvelles mesures obtenues
                for (let i = 0; i < mesures.mesures.length; i++) {
                    //UTC de la nouvelle valeur
                    let UTC =
                        Math.abs(
                            new Date(
                                mesures.mesures[i].timestamp * 1000
                            ).getTimezoneOffset()
                        ) *
                        60 *
                        1000;

                    //on ajoute la nouvelle valeur dans la nouvelle partie de l'index
                    newIndex.push([
                        mesures.mesures[i].timestamp * 1000 + UTC,
                        mesures.mesures[i].valeur / indexDivider +
                            newIndex[newIndex.length - 1][1],
                    ]);
                }

                //on concatène l'ancien index et le nouveau
                newIndex = index.concat(newIndex);

                //on met à jour l'état de l'index
                setIndex(newIndex);

                //on met à jour la date de fin
                setDate((prev) => ({
                    ...prev,
                    //date de la dernière mesure insérée
                    endDate: new Date(
                        manualMeasure[manualMeasure.length - 1][0]
                    ),
                }));
            } else if (index.length === 0) {
                //si c'est la première mesure manuelle rentrée par l'utilisateur

                //UTC de la dernière mesure manuelle
                let lastManualMeasureUTC =
                    1000 *
                    Math.abs(
                        new Date(
                            manualMeasure[manualMeasure.length - 1][0]
                        ).getTimezoneOffset() * 60
                    );

                //on requete les mesures de la dernière date de l'index à la nouvelle date de fin
                let mesures = await _requestGetMesures(
                    currentSensor,
                    (manualMeasure[0][0] - lastManualMeasureUTC)
                        .toString()
                        .slice(0, 10),
                    (
                        manualMeasure[manualMeasure.length - 1][0] -
                        lastManualMeasureUTC
                    )
                        .toString()
                        .slice(0, 10)
                );

                //on récupère la dernière mesure de l'index et on s'en sert comment valeur de départ pour la nouvelle partie de l'index
                let newIndex: [number, number][] = [manualMeasure[0]];

                //on parcours toutes les nouvelles mesures obtenues
                for (let i = 0; i < mesures.mesures.length; i++) {
                    //UTC de la nouvelle valeur
                    let UTC =
                        Math.abs(
                            new Date(
                                mesures.mesures[i].timestamp * 1000
                            ).getTimezoneOffset()
                        ) *
                        60 *
                        1000;

                    //on ajoute la nouvelle valeur dans la nouvelle partie de l'index
                    newIndex.push([
                        mesures.mesures[i].timestamp * 1000 + UTC,
                        mesures.mesures[i].valeur / indexDivider +
                            newIndex[newIndex.length - 1][1],
                    ]);
                }

                //on concatène l'ancien index et le nouveau
                newIndex = index.concat(newIndex);

                //on met à jour l'état de l'index
                setIndex(newIndex);

                //on met à jour la date de fin
                setDate((prev) => ({
                    ...prev,
                    //date de la dernière mesure insérée
                    endDate: new Date(
                        manualMeasure[manualMeasure.length - 1][0]
                    ),
                }));
            }
        };

        //si on a minimum 2 mesures manuelles on peut afficher le graphique
        if (manualMeasure.length > 1) {
            requestMesures();
        }

        // eslint-disable-next-line
    }, [manualMeasure]);

    return (
        <>
            {state != null && (
                <div id="calibration">
                    <Header
                        title={"Étalonnage: " + state.currentSensor.description}
                    />
                    <NavBarLogged />
                    <main>
                        {
                            <CalibrationContext.Provider
                                value={{
                                    currentSensor,
                                    setCurrentSensor,
                                    currentBat: state.currentBat,
                                    currentTab: currentTab,
                                    setCurrentTab: setCurrentTab,
                                    dataPiece: state.dataPiece,
                                    dataSensor: state.dataSensor,
                                    date: date,
                                    setDate: setDate,
                                    manualMeasure,
                                    setManualMeasure,
                                    index,
                                    setIndex,
                                    indexDivider,
                                    prefixUnit,
                                    realIndex,
                                    setRealIndex,
                                    estimateIndex,
                                    setEstimateIndex,
                                }}
                            >
                                <ChartCalibration dateBack={state.dateBack} />
                            </CalibrationContext.Provider>
                        }
                    </main>
                </div>
            )}
        </>
    );
};

export default CalibrationPage;
