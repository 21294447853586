import axios from "axios";

// Créez une instance Axios
const apiInstance = axios.create({
    baseURL: process.env.REACT_APP_MODE === "development" ? "http://localhost:3001" : process.env.REACT_APP_API_URL,
    withCredentials: true,
    headers: {
        "Access-Control-Allow-Origin": process.env.REACT_APP_MODE === 'development' ? 'http://localhost:3001' : process.env.REACT_APP_API_URL,
        "Access-Control-Allow-Headers": "Content-Type, Authorization, Access-Control-Allow-Origin",
    },
    
});

export default apiInstance;
