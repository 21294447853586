import fetchApi from "@api/fetchApi"
import { sensor, sensorId } from "@tsTypes/sensorType";

/**
 * @brief Fonction qui permet de récupérer les mesures manuelles d'un capteur
 * @param currentSensor : sensor --> capteur courant
 * @returns Promise<[number, number][]> --> liste des mesures mannuelles
 */
export async function _getManualMeasure(currentSensor: sensor) : Promise<[number, number][]> {
    let measure : {timestamp: number, idcompteur: sensorId, valeur: number}[] = (await fetchApi
        .post(`/manual-measure/get`, {
            sensorId: currentSensor.id_compteur,
        })).data;
    

    let res : [number, number][] = measure.map((measure: any) => {
        return [parseInt(measure.timestamp), measure.valeur];
    });

    return res;
}