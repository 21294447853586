export function determineRoomColor(confortIndex: number) {
    if(confortIndex <= 3) return "#33cc33";
    if(confortIndex <= 6) return "#ff9900";

    return "#ff3300";
}

export function determineTempTooltipColor(temperature: number) {

    const IDEAL_TEMP = 19;

    if(temperature <= IDEAL_TEMP +2 && temperature >= IDEAL_TEMP - 2) return "#33cc33";
    if(temperature <= IDEAL_TEMP + 4 && temperature > IDEAL_TEMP + 2) return "#ff9900";
    if(temperature > IDEAL_TEMP + 4) return "#ff3300";
    if(temperature < IDEAL_TEMP - 2 && temperature >= IDEAL_TEMP - 4) return "#3399ff";
    if(temperature <= IDEAL_TEMP - 5) return "#0033cc";
}
