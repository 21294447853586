import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App.tsx";
import { ChakraProvider } from "@chakra-ui/react";
import ErrorBoundary from "./components/errorBoundary/ErrorBoundary.tsx";
import reportWebVitals from "./reportWebVitals.js";
import ReactGA from "react-ga4";
import { sendToAnalytics } from "@api/analytics.ts";

//initialisation de Google Analytics
if(process.env.REACT_APP_GA_SEND === "true") {
    ReactGA.initialize(process.env.REACT_APP_GA_TOKEN);
    reportWebVitals(sendToAnalytics);
}


const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <ChakraProvider>
        <ErrorBoundary>
            <App />
        </ErrorBoundary>
    </ChakraProvider>
);
