import React from "react";
import { batiment } from "@tsTypes/mySitesTypes";
import Map, { Marker } from "react-map-gl";
import "./mapPanel.scss";

interface props {
    batiment: batiment;
}

export default function MapPanel({ batiment }: props) {

    return (
        <div className="map-panel" id="map-panel">
            {batiment !== undefined ? (
                <>
                    <Map
                        mapboxAccessToken="pk.eyJ1IjoiYXN0cmFmaXJlIiwiYSI6ImNsbHdhcWVsbDFsaXYza283bDg0OGF1NjgifQ.GQrZPcHEtktQ_na3ti4ebg"
                        initialViewState={{
                            longitude: parseFloat(batiment.longitude),
                            latitude: parseFloat(batiment.latitude),
                            zoom: 18,
                            pitch: 45,
                        }}
                        style={{ width: "100%", height: "100%" }}
                        mapStyle="mapbox://styles/astrafire/cly7bi7u300fv01qpchb7f681"
                    >
                        <Marker
                            key={batiment.id_bat}
                            longitude={batiment.longitude}
                            latitude={batiment.latitude}
                        />
                    </Map>
                </>
            ) : (
                <>
                    <p>Chargement de la carte...</p>
                </>
            )}
        </div>
    );
}
