import React, { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { useLoginStore } from "@store/loginStore.ts";

export default function Private() {
    //hook qui permet la redirection d'un utilisateur
    const navigate = useNavigate();
    const { isLogin } = useLoginStore()

    useEffect(() => {
        if (!isLogin) {
            navigate("/login");
        }
        //eslint-disable-next-line
    }, []);

    return (
        <>
            {isLogin && (
                    <Outlet />
            )}
        </>
    );
}
