import {
    Drawer,
    DrawerBody,
    DrawerCloseButton,
    DrawerContent,
    DrawerHeader,
    DrawerOverlay,
} from "@chakra-ui/react";
import { sensor } from "@tsTypes/sensorType";
import React, { useMemo } from "react";
import "./subCounterDrawer.scss";
import { MdArrowCircleRight, MdOutlineArrowCircleRight } from "react-icons/md";

interface props {
    isOpen: boolean;
    onClose: () => void;
    sensors: sensor[];
    currentSensor: sensor;
    setCurrentSensor: (sensor: sensor) => void;
}

export default function SubCounterDrawer({
    isOpen,
    onClose,
    sensors,
    currentSensor,
    setCurrentSensor,
}: props) {
    
    const sensor = useMemo<sensor>(() => {
        if(!currentSensor?.sous_compteur) {
            return sensors.find((sensor) => sensor?.id_compteur === currentSensor?.id_compteur);
        }else {
            return sensors.find((sensor) => sensor?.capteurs_Sous_Comptage.some((subCounter) => subCounter.id_compteur === currentSensor?.id_compteur));
        }
    }, [currentSensor, sensors])
    
    if (!sensors || !currentSensor) return null;
    
    return (
        <Drawer
            isOpen={isOpen}
            onClose={onClose}
            size="md"
            id="sub-counter-drawer"
        >
            <DrawerOverlay />
            <DrawerContent>
                <DrawerCloseButton />
                <DrawerHeader id="header">
                    <h1>Sous comptage</h1>
                </DrawerHeader>
                <DrawerBody id="body">
                    <button
                        id="main-sensor"
                        onClick={() => {
                            setCurrentSensor(sensor);
                            onClose();
                        }}
                    >
                        <MdArrowCircleRight color="#225522" />
                        {sensor.description}
                    </button>
                    <div id="sub-counter-list">
                        {sensor.capteurs_Sous_Comptage.map((subCounter) => (
                            <button
                                onClick={() => {
                                    setCurrentSensor(subCounter);
                                    onClose();
                                }}
                                key={subCounter.id_compteur}
                            >
                                <MdOutlineArrowCircleRight color="#225522" />
                                {subCounter.description}
                            </button>
                        ))}
                    </div>
                </DrawerBody>
            </DrawerContent>
        </Drawer>
    );
}
