import { GetFetcher } from "@api/SwrFetcher";
import { Button, Flex, Menu, MenuButton, MenuList } from "@chakra-ui/react";
import { sensor } from "@tsTypes/sensorType";
import {
    addDays,
    differenceInCalendarDays,
    endOfDay,
    isSameDay,
    startOfDay,
    subDays,
    subSeconds,
} from "date-fns";
import { fr } from "date-fns/locale";
import React, { useEffect, useState } from "react";
import { DateRangePicker } from "react-date-range";
import useSWR from "swr";
import "./dateRange.scss";

interface props {
    currentSensor: sensor;
    currentDate: {
        startDate: Date;
        endDate: Date;
    };
    setCurrentDate: (date: { startDate: Date; endDate: Date }) => void;
}

const staticRanges = [
    {
        label: "Aujourd'hui",
        range: () => ({
            startDate: new Date(),
            endDate: new Date(),
        }),
        isSelected: () => false,
    },
    {
        label: "Hier",
        range: () => ({
            startDate: subDays(new Date(), 1),
            endDate: subDays(new Date(), 1),
        }),
        isSelected: () => false,
    },
    {
        label: "Cette semaine",
        range: () => ({
            startDate: subDays(new Date(), 7),
            endDate: new Date(),
        }),
        isSelected: () => false,
    },
    {
        label: "La semaine dernière",
        range: () => ({
            startDate: subDays(new Date(), 14),
            endDate: subDays(new Date(), 8),
        }),
        isSelected: () => false,
    },
    {
        label: "Ce mois",
        range: () => ({
            startDate: new Date(
                new Date().getFullYear(),
                new Date().getMonth(),
                1
            ),
            endDate: new Date(),
        }),
        isSelected: () => false,
    },
    {
        label: "Le mois dernier",
        range: () => ({
            startDate: new Date(
                new Date().getFullYear(),
                new Date().getMonth() - 1,
                1
            ),
            endDate: new Date(
                new Date().getFullYear(),
                new Date().getMonth(),
                0
            ),
        }),
        isSelected: () => false,
    },
    {
        label: "Cette année",
        range: () => ({
            startDate: new Date(new Date().getFullYear(), 0, 1),
            endDate: new Date(),
        }),
        isSelected: () => false,
    },
];

export default function DateRange({
    currentSensor,
    currentDate,
    setCurrentDate,
}: props) {
    //fetch de la date min et de la date max
    const { data, isLoading } = useSWR(
        {
            url: "/sensor/get-min-max-mesures",
            params: {
                idSensor: currentSensor.id_compteur,
                type: currentSensor.type_energie,
            },
        },
        GetFetcher,
        {
            revalidateOnFocus: false,
        }
    );

    //state permettant de gérer la date
    const [date, setDate] = useState({
        selection: {
            startDate: new Date(),
            endDate: new Date(),
            key: "selection",
        },
    });

    //state permettant de gérer l'ouverture et la fermeture du menu de dates
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    useEffect(() => {
        if (!data?.min && !data?.max) return;

        //une fois que le min et le max sont chargés, on met à jour la date si la date courante est nulle on la remplie avec les valeurs par défaut
        //sinon on la laisse telle quelle car cela veut dire que l'on a une date set depuis une autre page
        if (!currentDate.startDate && !currentDate.endDate) {
            setDate({
                selection: {
                    startDate: new Date(
                        subDays(
                            new Date(data.max.timestamp * 1000),
                            7
                        ).setHours(0, 0, 0, 0)
                    ),
                    endDate: new Date(data.max.timestamp * 1000),
                    key: "selection",
                },
            });
            setCurrentDate({
                startDate: new Date(
                    subDays(new Date(data.max.timestamp * 1000), 7).setHours(0, 0, 0, 0)
                ),
                endDate: new Date(data.max.timestamp * 1000),
            });
        } else {
            setDate({
                selection: {
                    startDate: currentDate.startDate,
                    endDate: currentDate.endDate,
                    key: "selection",
                },
            });
        }
        //eslint-disable-next-line
    }, [data]);

    if (isLoading) return <div>Chargement...</div>;

    return (
        <Menu
            closeOnSelect={false}
            onClose={() => setIsMenuOpen(false)}
            onOpen={() => setIsMenuOpen(true)}
            isOpen={isMenuOpen}
        >
            <MenuButton
                className="date-button"
                as={Button}
                backgroundColor={"#225522"}
                color={"white"}
            >
                {date.selection.startDate.toLocaleDateString("fr")} -{" "}
                {date.selection.endDate.toLocaleDateString("fr")}
            </MenuButton>
            <MenuList>
                <Flex
                    direction={"column"}
                    gap={5}
                >
                    <DateRangePicker
                        onChange={(item) => {
                            setDate({ ...date, ...item });
                        }}
                        ranges={[date.selection]}
                        locale={fr}
                        ariaLabels={{
                            monthPicker: "Mois",
                            yearPicker: "Année",
                            nextButton: "Suivant",
                            prevButton: "Précédent",
                        }}
                        staticRanges={staticRanges}
                        inputRanges={[
                            {
                                label: "Jour en arrière",
                                range(value) {
                                    return {
                                        startDate: addDays(
                                            startOfDay(new Date()),
                                            (Math.max(Number(value), 1) - 1) *
                                                -1
                                        ),
                                        endDate: endOfDay(new Date()),
                                    };
                                },
                                getCurrentValue(range) {
                                    if (
                                        !isSameDay(
                                            range.endDate,
                                            endOfDay(new Date())
                                        )
                                    )
                                        return "-";
                                    if (!range.startDate) return "∞";
                                    return (
                                        differenceInCalendarDays(
                                            endOfDay(new Date()),
                                            range.startDate
                                        ) + 1
                                    );
                                },
                            },
                        ]}
                        rangeColors={["#225522"]}
                        minDate={
                            !data?.min
                                ? new Date()
                                : new Date(data.min.timestamp * 1000)
                        }
                        maxDate={
                            !data?.max
                                ? new Date()
                                : new Date(data.max.timestamp * 1000)
                        }
                    />
                    <Button
                        backgroundColor="#225522"
                        color="white"
                        onClick={() => {
                            setIsMenuOpen(false);
                            setCurrentDate({
                                startDate: date.selection.startDate,
                                endDate: subSeconds(
                                    addDays(date.selection.endDate, 1),
                                    1
                                ),
                            });
                        }}
                        marginLeft={10}
                        marginRight={10}
                    >
                        Valider
                    </Button>
                </Flex>
            </MenuList>
        </Menu>
    );
}
