import { AxiosError } from "axios";
import apiInstance from "./axiosConfig";
import { useLoginStore } from "@store/loginStore";

//classe pour pouvoir faire des requêtes http et catch les erreurs pour les traiter
export default class fetchApi {
    static apiInstance = apiInstance;

    //requete post
    static async post(url: string, data: any) {
        let res;

        try {
            res = await this.apiInstance.post(url, data);
        } catch (error: any) {
            fetchApi.treatError(error);
        }

        return res;
    }

    //requete get
    static async get(url: string, params?: any) {
        let res;

        try {
            res = await this.apiInstance.get(url, { params: params });
        } catch (error: any) {
            fetchApi.treatError(error);
        }

        return res;
    }

    static treatError(error: AxiosError) {
        //si la requête est unauthorize, on déconnecte l'utilisateur pour qu'il se reconnecte car sa session a expiré
        if (error.response?.data === "Unauthorized") {
            //on supprime le token du local storage et on redirige l'utilisateur vers la page de connexion et on met à jour le store
            localStorage.removeItem("token");
            window.location.href = "/login";
            useLoginStore.getState().logout();
        }
    }
}
