import React, { useRef, useState } from "react";
import "./navBarLogged.scss";
import { FaCity } from "react-icons/fa6";
import { IoMenu } from "react-icons/io5";
import { FaBars, FaMapMarked } from "react-icons/fa";
import LogoKwh from "@assets/img/logo/kWh50-Logo_Couleursfond blanc.png";
import { NavLink, Outlet } from "react-router-dom";
import LogoutButton from "@components/logoutButton/LogoutButton";
import { AiFillDashboard } from "react-icons/ai";
import {
    Button,
    Drawer,
    DrawerBody,
    DrawerCloseButton,
    DrawerContent,
    DrawerFooter,
    DrawerHeader,
    useDisclosure,
} from "@chakra-ui/react";
import { MdLogout } from "react-icons/md";

const NavBarLogged = () => {
    const menuItem = [
        {
            path: "/user/mes-sites",
            name: "Mes sites",
            icon: <FaCity />,
        },
        {
            path: "/user/ma-carte",
            name: "Ma carte",
            icon: <FaMapMarked />,
        },
        {
            path: "/user/dashboard",
            name: "Tableau de bord",
            icon: <AiFillDashboard />,
        },
        /* {
            path: "/",
            name: "Mes déclarations",
            icon: <FaNewspaper />,
        }, */
        /* {
            path: "/",
            name: "Gérer les accès collaborateurs",
            icon: <IoPersonAddSharp />,
        }, */
    ];
    const [isLoggedNavIsOpen, setisLoggedNavIsOpen] = useState(false);
    const toggle = () => setisLoggedNavIsOpen(!isLoggedNavIsOpen);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const btnRef = useRef(null);

    return (
        <>
            <div className="navBarLogged">
                <div
                    style={{
                        width: isLoggedNavIsOpen ? "300px" : "75px",
                        transition: "0.5s",
                    }}
                    className="sidebar"
                >
                    <div
                        className="top_section"
                        style={{
                            justifyContent: isLoggedNavIsOpen
                                ? "space-between"
                                : "center",
                        }}
                    >
                        <NavLink to="/">
                            <img
                                className="logo"
                                src={LogoKwh}
                                alt="Logo Kilowattheure50"
                                style={{
                                    display: isLoggedNavIsOpen
                                        ? "block"
                                        : "none",
                                    cursor: "pointer",
                                }}
                            />
                        </NavLink>
                        <div
                            style={{
                                marginLeft: isLoggedNavIsOpen ? "50px" : "0",
                            }}
                            className="bars"
                        >
                            <FaBars onClick={toggle} />
                        </div>
                    </div>
                    {menuItem.map((item, index) => (
                        <NavLink
                            to={item.path}
                            key={index}
                            style={{
                                height: isLoggedNavIsOpen
                                    ? "fit-content"
                                    : "75px",
                                alignItems: "center",
                                justifyContent: isLoggedNavIsOpen
                                    ? "flex-start"
                                    : "center",
                            }}
                            className="link"
                        >
                            <div className="icon">{item.icon}</div>
                            <div
                                style={{
                                    display: isLoggedNavIsOpen
                                        ? "block"
                                        : "none",
                                }}
                                className="link_text"
                            >
                                {item.name}
                            </div>
                        </NavLink>
                    ))}
                </div>
                <div
                    className="bottomSection"
                    style={{ display: isLoggedNavIsOpen ? "flex" : "none" }}
                >
                    <LogoutButton />
                </div>
                <div
                    className="bottomSection"
                    style={{ display: !isLoggedNavIsOpen ? "flex" : "none" }}
                >
                    <NavLink to="/user/logout">
                        <MdLogout size={28} />
                    </NavLink>
                </div>
                <Outlet />
            </div>
            <div className="navbar-mobile">
                <Button
                    ref={btnRef}
                    onClick={onOpen}
                >
                    <IoMenu
                        size={40}
                        color={"white"}
                    />
                </Button>
                <Drawer
                    isOpen={isOpen}
                    placement="left"
                    onClose={onClose}
                >
                    <DrawerContent className="drawer-logged-navbar-mobile">
                        <DrawerCloseButton className="close-button" />
                        <DrawerHeader>
                            <NavLink to="/">
                                <img
                                    className="logo"
                                    src={LogoKwh}
                                    alt="Logo Kilowattheure50"
                                    style={{ cursor: "pointer" }}
                                />
                            </NavLink>
                        </DrawerHeader>
                        <DrawerBody className="drawer-body-logged-navbar-mobile">
                            {menuItem.map((item, index) => (
                                <NavLink
                                    to={item.path}
                                    key={index}
                                    className="link"
                                >
                                    <div className="icon">{item.icon}</div>
                                    <div className="link_text">{item.name}</div>
                                </NavLink>
                            ))}
                        </DrawerBody>
                        <DrawerFooter className="drawer-footer-logged-navbar-mobile">
                            <LogoutButton />
                        </DrawerFooter>
                    </DrawerContent>
                </Drawer>
            </div>
        </>
    );
};

export default NavBarLogged;
