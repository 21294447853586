import ReactGA from "react-ga4";

export function sendGAPageView(title: string, window: Window,) {

    if (process.env.REACT_APP_GA_SEND === "true") {
        ReactGA.send({
            hitType: "pageview",
            page: window.location.pathname,
            title: title,
        });
    }
}

export function sendGAEvent(categorie: string, action: string, label: string) {

    if(process.env.REACT_APP_GA_SEND === "true") {
        ReactGA.event({
            category: categorie,
            action: action,
            label: label,
        });
    }
}

export function sendToAnalytics({
    id,
    name,
    value,
}: {
    id: string;
    name: string;
    value: number;
}) {
    ReactGA.event({        category: "Web Vitals",
        action: name,
        value: Math.round(name === "CLS" ? value * 1000 : value), // values must be integers
        label: id, // id unique to current page load
        nonInteraction: true, // avoids affecting bounce rate
    });
}