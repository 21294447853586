import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useLoginStore } from "@store/loginStore";

export function useAlreadyLogged() {
    const navigate = useNavigate();

    const { isLogin } = useLoginStore();

    useEffect(() => {
        if (isLogin) {
            navigate("/user/mes-sites");
        }
        // eslint-disable-next-line
    }, []);
}
