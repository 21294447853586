import React, { useEffect } from "react";
import fetchApi from "@api/fetchApi";
import { useNavigate } from "react-router-dom";
import { useLoginStore } from "@store/loginStore";

export default function Logout() {
    const navigate = useNavigate();
    const { logout } = useLoginStore();

    useEffect(() => {
        fetchApi.get("/login/logout").finally(() => {
            logout();
            navigate("/");
        });
    });

    return <div></div>;
}
