import { mesure, sensor } from '@tsTypes/sensorType';
import { formatDatesGetMesures } from '@utils/formatDatesGetMesures';


/**
 * Fonction permettant de formater les données pour le graphique
 * @param _mesures
 * @param _currentSensor
 * @returns
 */
export function formatChartDatas(
	mesure: mesure[],
	currentSensor: sensor
): { mesure: [number, number][]; index: [number, number][] } {
	if (!mesure || mesure.length === 0) return { mesure: [], index: [] };

	//si la dernière mesure du jour précédent est null on la supprime car cela veut dire que la première mesure du jour est un trou (consommation pendant le trou)
	if (mesure[0].valeur === null) {
		mesure.shift();
		mesure.shift();
	} else {
		mesure.shift();
	}

	let index: [number, number][] = [];
	let convertedMesures: [number, number][] = [];
	let indexCoef: number = currentSensor.unite === 'm3' ? 1.12 : 1;

	for (let i = 0; i < mesure.length; i++) {
		//on ajoute la mesure dans le tableau de retour
		convertedMesures.push([
			Date.parse(formatDatesGetMesures(new Date(mesure[i].timestamp))),
			mesure[i].valeur === null ? NaN : Number(mesure[i].valeur) * indexCoef,
		]);

		//si l'on a un trou de mesure on passe à la suivante
		if (mesure[i].valeur === null) {
			if (i < mesure.length - 1) {
				if (currentSensor.type_energie === 'électricité' || currentSensor.type_energie === 'gaz') {
					_pushIndex(mesure[i + 1], index, indexCoef);
				}

				i++;
			}
		} else {
			//si c'est une énergie on calcule l'index
			if (currentSensor.type_energie === 'électricité' || currentSensor.type_energie === 'gaz') {
				_pushIndex(mesure[i], index, indexCoef);
			}
		}
	}

	return { mesure: convertedMesures, index };
}

function _pushIndex(mesure: mesure, index: [number, number][], indexCoef: number): void {
	if (index.length === 0) {
		index.push([
			Date.parse(formatDatesGetMesures(new Date(mesure.timestamp))),
			Number((mesure.valeur * indexCoef).toFixed(2)),
		]);
	} else {
		index.push([
			Date.parse(formatDatesGetMesures(new Date(mesure.timestamp))),
			Number((index[index.length - 1][1] + mesure.valeur * indexCoef).toFixed(2)),
		]);
	}
}

export function determineUnit(
	_maxValue: number,
	_currentSensor: sensor
): { divider: number; unit: string } {
	let unitDivider = 1;
	let unit = 'k';

	//si la valeur est une énergie
	if (
		_currentSensor.type_energie === 'électricité' ||
		(_currentSensor.type_energie === 'gaz' && _currentSensor.unite === 'Wh')
	) {
		if (_maxValue >= 1000000000) {
			unitDivider = 1000000000;
			unit = 'G';
		} else if (_maxValue >= 10000000) {
			unitDivider = 1000000;
			unit = 'M';
		}
	}

	return { divider: unitDivider, unit: unit };
}

export function applyDataUnits(
	_mesures: [number, number][],
	_currentSensor: sensor,
	_index: [number, number][]
): any {
	let maxDataValue;

	//on récupère la valeur maximale des données uniquement si le capteur est un de l'électricité
	if (_currentSensor.type_energie === 'électricité') {
		maxDataValue = _mesures.reduce((max, [_, deuxiemeValeur]: any) => {
			// Vérifier si la deuxième valeur est null ou non
			if (deuxiemeValeur !== null) {
				// Mettre à jour la valeur maximale si la deuxième valeur est supérieure
				return deuxiemeValeur > max ? deuxiemeValeur : max;
			} else {
				// Ne rien faire si la deuxième valeur est null
				return max;
			}
		}, Number.MIN_SAFE_INTEGER);
	}

	let maxIndexValue;

	//on récupère la valeur maximale de l'index uniquement si le capteur est une énergie
	if (_currentSensor.type_energie === 'électricité' || _currentSensor.type_energie === 'gaz') {
		if (_index.length > 0) {
			let i = _index.length - 1;

			while (_index[i] === null) {
				i--;
			}

			maxIndexValue = _index[i][1];
		}
	}

	let { divider: indexDivider, unit: indexUnit } = determineUnit(maxIndexValue, _currentSensor);
	let { divider: dataDivider, unit: dataUnit } = determineUnit(maxDataValue, _currentSensor);

	let index = _index.map(([date, value]) => [date, value / indexDivider]);
	let data = _mesures.map(([date, value]) => [date, value / dataDivider]);

	return { index, data, indexUnit, dataUnit, indexDivider };
}
