/**
 * Fonction permettant de mettre en évidence les occurences d'une valeur recherchée dans une chaîne de caractères
 * @param _string chaines de caractères dans laquelle on recherche
 * @param _searchValue valeur recherchée
 * @returns _string avec les occurences de _searchValue entourées de balises <em>
 */
export function highLightResearch (_string: string, _searchValue: string) {

    const regex = new RegExp(_searchValue, 'gi');
  
    // Remplacer chaque occurrence de la sous-chaîne par la sous-chaîne entourée de balises <em>
    _string = _string.replace(regex, `<em>${_searchValue.toUpperCase()}</em>`);
    
    return _string;
};