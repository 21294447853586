import { batiment } from '@tsTypes/mySitesTypes'
import React, { MutableRefObject } from 'react'
import { Link } from 'react-router-dom';
import './mapBatimentMenuItem.scss'
import { MapRef } from 'react-map-gl';

export default function MapBatimentMenuItem({ batiment, nomSite, map}: { batiment: batiment, nomSite: string, map: MutableRefObject<MapRef> | null}) {
  return (
      <div className="map-batiment-menu-item" onClick={() => {
            map.current.flyTo({center: [parseFloat(batiment.longitude), parseFloat(batiment.latitude)], duration: 2000});
      }}>
          <p>{batiment.nom_bat}</p>
          <Link
              to={"/user/mon-batiment"}
              state={{
                  batiment: batiment,
                  nomSite: nomSite,
              }}
          >
              Voir mon site
          </Link>
      </div>
  );
}
