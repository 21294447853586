import Options from "@highcharts/dashboards/es-modules/Dashboards/Components/HighchartsComponent/HighchartsComponentOptions";

type DashBoardSerie = {
    description: string;
    data: number[];
};

export function Chart(renderTo: string, title: string, data: DashBoardSerie[], xLabels: string[]) {

    let series = data.map((serie) => {
        return {
            name: serie.description,
            data: serie.data,
            type: "column",
            tooltip: {
                valueSuffix: " kWh",
            }
        }
    })

    return {
        renderTo: renderTo,
        type: "Highcharts",
        chartOptions: {
            plotOptions: {
                column: {
                    stacking: "normal",
                },
            },
            title: {
                text: title,
            },
            series: series,
            xAxis: {
                categories: xLabels,
            },
            yAxis: {
                title: {
                    text: "kWh",
                },
            },
        },
    } as Options;
}