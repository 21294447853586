import { Button, Menu, MenuButton, MenuItem, MenuList } from "@chakra-ui/react";
import { batiment } from "@tsTypes/mySitesTypes";
import { sensor } from "@tsTypes/sensorType";
import React from "react";
import { Link } from "react-router-dom";

interface Props {
    currentSensor: sensor;
    currentDate: { startDate: Date; endDate: Date };
    currentBatiment: batiment;
}

export default function SettingsButton({
    currentSensor,
    currentDate,
    currentBatiment,
}: Props) {
    return (
        <>
            {(currentSensor.type_energie === "gaz" ||
                currentSensor.type_energie === "électricité") && (
                <Menu>
                    <MenuButton as={Button}>Paramètres</MenuButton>
                    <MenuList>
                        <MenuItem>
                            <Link
                                to="/user/etalonnage"
                                state={{
                                    currentSensor: currentSensor,
                                    dateBack: currentDate,
                                    currentBat: currentBatiment,
                                }}
                            >
                                Étalonnage
                            </Link>
                        </MenuItem>
                    </MenuList>
                </Menu>
            )}
        </>
    );
}
