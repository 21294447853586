import React, { useEffect, useState } from "react";
import MySiteAccordion from "@pages/private/user/mySitesPage/components/mySiteAccordion/MySitesAccordion";
import SearchBar from "@pages/private/user/mySitesPage/components/mySiteAccordion/components/searchBar/SearchBar";
import { site } from "@tsTypes/mySitesTypes.ts";
import "./mySitesPage.scss";
import NavBarLogged from "@components/navBarLogged/NavbarLogged.tsx";
import { Spinner } from "@chakra-ui/react";
import Header from "@components/header/Header.tsx";
import { handleResearch } from "@utils/handleResearch.ts";
import fetchApi from "@api/fetchApi";
import { sendGAPageView } from "@api/analytics";

const MySitesPage = () => {

    document.title = "kWh50 - Mes sites";

    const [dataSite, setDataSite] = useState<site[]>();
    const [dataSiteFix, setDataSiteFix] = useState<site[]>();

    //On récupère les sites de l'utilisateur
    useEffect(() => {

        sendGAPageView(
            "Page mes sites",
            window
        );

        fetchApi.get("/my-sites/get-sites").then((_res) => {
            setDataSiteFix(_res.data.data);
            setDataSite(_res.data.data);
        });
        // eslint-disable-next-line
    }, []);

    //Fonction qui gère la recherche
    const handleSearch = async (_value: string) => {
        let res = await handleResearch(_value, dataSiteFix);

        setDataSite(res);
    };

    return (
        <div className="mySitesPage">
            <Header title="Mes sites" />
            <NavBarLogged />
            <main>
                <SearchBar research={handleSearch} />
                {!dataSite && (
                    <Spinner
                        thickness="4px"
                        speed="0.65s"
                        emptyColor="gray.200"
                        color="#225522"
                        size="xl"
                    />
                )}
                {dataSite && <MySiteAccordion dataSite={dataSite} />}
            </main>
        </div>
    );
};

export default MySitesPage;
