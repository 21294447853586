import React from "react";
import logo from "@assets/img/logo/kWh50-Logo_Couleursfond blanc.png";
import ErrorIMG from "@assets/gif/500.svg";
import "./errorBoundary.scss";
import { Link } from "@chakra-ui/react";
import fetchApi from "@api/fetchApi";

type ErrorBoundaryState = {
    hasError: boolean;
    errorMsg: string;
};

export default class ErrorBoundary extends React.Component<
    { children: React.ReactNode },
    ErrorBoundaryState,
    { ref: React.RefObject<HTMLImageElement> }
> {
    ref: React.RefObject<HTMLImageElement>;

    constructor(props: any) {
        super(props);
        this.state = { hasError: false, errorMsg: "" };
        this.ref = React.createRef();
    }

    static getDerivedStateFromError(error: Error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true, errorMsg: error.toString() };
    }

    componentDidCatch(error: Error, info: any) {
        if (process.env.NODE_ENV === "development") {
            console.error(error, info.componentStack);
        } else {
            //envoie de l'erreur dans un fichier de log
            fetchApi.post("/logs/crash-app", {
                error: error.toString(),
                info: info.componentStack,
            });
        }
    }

    componentDidUpdate(): void {
        //lancement de l'animation du gif
        if (this.state.hasError) {
            window.setTimeout(() => {
                this.ref.current.classList.add("animated");
            }, 1000);
        }
    }

    render() {
        if (this.state.hasError) {
            return (
                <div className="error-boundary">
                    <img
                        src={logo}
                        alt="Logo kWh50"
                    />
                    <section>
                        <div className="text">
                            <h1>Oups</h1>
                            <h2>quelque chose s'est mal passé ...</h2>
                            <p>
                                Pour nous faire pardonner voici quelques liens
                                utiles :
                            </p>
                            <ul>
                                <li>
                                    <Link href="/">Accueil</Link>
                                </li>
                                <li>
                                    <Link href="/user/mes-sites">
                                        Mes sites
                                    </Link>
                                </li>
                                <li>
                                    <Link href="/user/ma-carte">Ma carte</Link>
                                </li>
                                <li>
                                    <Link href="/user/dashboard">
                                        Mon tableau de bord
                                    </Link>
                                </li>
                            </ul>
                        </div>
                        <img
                            src={ErrorIMG}
                            alt="500"
                            ref={this.ref}
                            className="error-img"
                        />
                    </section>
                    <a
                        href="https://storyset.com/people"
                        className="link"
                    >
                        People illustrations by Storyset
                    </a>
                </div>
            );
        }

        return this.props.children;
    }
}
