import { batiment } from '@tsTypes/mySitesTypes';
import React, { useCallback, useEffect, useState } from 'react';
import './panel3d.scss';
import useSWR from 'swr';
import { GetFetcher } from '@api/SwrFetcher';
import Tooltip from './components/tooltip/Tooltip';
import { determineTempTooltipColor } from '@utils/dashboardBatiment';
import { AssetRoom, AssetSensor } from '@tsTypes/smplrspaceTypes';
import { Space } from '@smplrspace/smplr-loader/dist/generated/smplr';
import Viewer from './components/viewer/Viewer';
import { Switch, useDisclosure } from '@chakra-ui/react';
import Loading from '@components/loading/Loading';
import RoomDrawer from './components/roomDrawer/RoomDrawer';
import { formatDistanceToNow } from 'date-fns';
import { fr } from 'date-fns/locale';

interface props {
	batiment: batiment;
}

export default function Panel3d({ batiment }: props) {
	//récupération des dernières données des pièces
	const { data: roomDatas, isLoading } = useSWR(
		{
			url: '/dashboard-batiment/get-room-datas',
			params: { idbat: batiment.id_bat },
		},
		GetFetcher,
		//refresh toutes les 5 minutes
		{ refreshInterval: 5 * 60 * 1000, revalidateOnFocus: false }
	);

	//récupération des dernières données des compteurs
	const { data: sensorsData } = useSWR(
		{
			url: '/dashboard-batiment/get-sensors-datas',
			params: { idbat: batiment.id_bat },
		},
		GetFetcher,
		//refresh toutes les 5 minutes
		{ refreshInterval: 5 * 60 * 1000, revalidateOnFocus: false }
	);

	//state pour stocker l'instance de l'espace 3D
	const [space, setSpace] = useState<Space>();

	//state pour stocker les données de la pièce
	const [roomData, setRoomData] = useState<AssetRoom>();

	//state pour gérer l'ouverture et la fermeture du drawer
	const { isOpen, onClose, onOpen } = useDisclosure();

	//callback pour récupérer l'instance de l'espace 3D
	const onReady = useCallback((space: Space) => setSpace(space), []);

	//ajout des layers des pièces dans l'espace 3D
	useEffect(() => {
		if (!roomDatas || !space) return;

		//récupération des données de la pièce en json afin de surligner les pièces dans l'espace 3D
		let piecesData: AssetRoom[] = roomDatas.data;

		//ajout des layers des pièces dans l'espace 3D
		space.addDataLayer({
			id: 'pieces',
			data: piecesData as any,
			type: 'polygon',
			color: (d: any) => determineTempTooltipColor(d.data[0].valeur),
			alpha: 0.5,
			//affichage du tooltip au survol de la pièce
			tooltip: (d: any) => Tooltip(d),
			//ouverture du drawer au clic sur la pièce
			onClick: (d: any) => {
				//mise à jour des données de la pièce et ouverture du drawer
				setRoomData(d);
				onOpen();
				//mise à jour du layer pour n'afficher que la pièce cliquée
				const roomLayer = space.getDataLayer('pieces');

				roomLayer.update({
					data: [piecesData.find((p) => p.id === d.id) as any],
				});

				//mise à jour du layer des capteurs pour les masquer
				const sensorLayer = space.getDataLayer('sensors');

				//on masque les capteurs
				sensorLayer.update({
					data: sensorsData.data,
					color: null,
				});

				//affichage des pièces jusqu'au niveau de la pièce cliquée
				space.showUpToLevel(d.levelIndex);

				//centrage de la caméra sur la pièce cliquée
				space.setCameraPlacement({
					target: {
						x:
							//on récupère le premier point et le point du milieu de la liste de coordonné pour en faire la moyenne afin de centrer la caméra
							(d.coordinates[0].x + d.coordinates[Math.floor(d.coordinates.length / 2)].x) / 2,
						y: (d.coordinates[0].y + d.coordinates[Math.floor(d.coordinates.length / 2)].y) / 2,
						z: (d.coordinates[0].z + d.coordinates[Math.floor(d.coordinates.length / 2)].z) / 2,
					},
					//on augmente le rayon de la caméra en fonction du niveau de la pièce pour éviter que la caméra ne soit trop proche de la pièce
					//ou ne centre absolument pas la pièce
					radius: 20 + 5 * d.levelIndex,
					beta: (2 * Math.PI) / 10,
					animate: true,
					animationDuration: 1,
				});
				//démarrage de la rotation automatique
				space.startAutoRotation(0.5);
			},
		});

		// ajout des layes des capteurs de confort dans l'espace 3D
		piecesData.map((piece) => {
			if (piece.capteur_assets != null) {
				space.addDataLayer({
					id: piece.id_piece,
					data: [piece.capteur_assets] as any,
					type: 'point',
					color: (d: any) => 'red',
					diameter: 0.5,
					tooltip: (d: any) => 'Capteur de confort',
				} as any);
			}
		});

		return () => {
			space.removeDataLayer('pieces');
			piecesData.map((piece) => {
				if (piece.capteur_assets != null) {
					space.removeDataLayer(piece.id_piece);
				}
			});
		};
	}, [roomDatas, space, onOpen]);

	//ajout des layers des capteurs dans l'espace 3D
	useEffect(() => {
		if (!sensorsData || !space) return;

		let sensors: AssetSensor[] = sensorsData.data;

		space.addDataLayer({
			id: 'sensors',
			data: sensors as any,
			type: 'furniture',
			color: (d: any) => (d.data.type === 'électricité' ? 'red' : 'yellow'),
			tooltip: (d: any) =>
				`${d.description} : <b>${d.data.valeur} ${d.data.unite}</b> il y a ${formatDistanceToNow(
					new Date(d.data.timestamp * 1000),
					{ locale: fr }
				)}`,
		});

		return () => space.removeDataLayer('sensors');
	}, [sensorsData, space]);

	return (
		<>
			{isLoading ? (
				<Loading />
			) : (
				<div className="panel-3d">
					<div className="switch-mode">
						<p>Activer le mode 2D</p>
						<Switch
							colorScheme="green"
							disabled={space ? false : true}
							onChange={(e) => (e.target.checked ? space.setMode('2d') : space.setMode('3d'))}
						/>
					</div>
					<Viewer onReady={onReady} spaceIdBatiment={batiment.id_smplr} />
					<RoomDrawer
						d={roomData}
						isOpen={isOpen}
						onClose={() => {
							//fermeture du drawer et réaffichage de toutes les pièces
							const layer = space.getDataLayer('pieces');
							layer.update({
								data: roomDatas.data,
							});

							//réaffichage des capteurs
							const sensorLayer = space.getDataLayer('sensors');
							sensorLayer.update({
								data: sensorsData.data,
								color: (d: any) => (d.data.type === 'électricité' ? 'red' : 'yellow'),
							});

							//arrêt de la rotation automatique et recentrage de la caméra
							space.stopAutoRotation();
							space.centerCamera();
							onClose();
						}}
					/>
				</div>
			)}
		</>
	);
}
