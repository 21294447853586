import { Button } from "@chakra-ui/react";
import React from "react";
import './dashboardTitle.scss'
import { LuCalendarCheck } from "react-icons/lu";   

export default function DashboardTitle({
    onOpen,
    toggleBtn,
    title,
}: {
    onOpen: () => void;
    toggleBtn: React.RefObject<HTMLButtonElement>;
    title: string;
}) {
    return (
        <div className="dashboard-title">
            <h1>{title}</h1>
            <Button
                ref={toggleBtn}
                onClick={onOpen}
            >
                Dates <LuCalendarCheck />
            </Button>
        </div>
    );
}
