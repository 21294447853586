import { CreateToastFnReturn } from '@chakra-ui/react';
import { batiment } from '@tsTypes/mySitesTypes';
import { sensor } from '@tsTypes/sensorType';
import Highcharts, { PointOptionsObject } from 'highcharts';

export function CovChart(
	sensor: sensor,
	batiment: batiment,
	mesures: [number, number][],
	index: [number, number][],
	plotbands: Highcharts.AxisPlotBandsOptions[],
	toast: CreateToastFnReturn = null,
	simpleMode: boolean = false
): Highcharts.Options {
	return {
		chart: {
			type: 'area',
			marginLeft: 75,
			zooming: {
				type: 'x',
				mouseWheel: {
					enabled: true,
				},
			},
			/*events: {
                load: !simpleMode ? function () {
                    const chart = this;

                    // Bouton de réinitialisation du zoom
                    chart.renderer
                        .button("Reset zoom", 0, 0, function () {
                            chart.zoomOut();
                        })
                        .attr({ zIndex: 999 })
                        .align({ align: "right", y: 90, x: -100 })
                        .add();
                } : null,
            },*/
		},
		responsive: {
			rules: [
				{
					condition: {
						maxWidth: 910,
					},
					chartOptions: {
						title: {
							text: 'COV',
						},
					},
				},
			],
		},
		plotOptions: {
			column: {
				pointPlacement: 'between',
				pointRange: 0,
			},
		},
		rangeSelector: {
			enabled: false,
		},
		navigator: {
			enabled: simpleMode ? false : true,
		},
		legend: {
			enabled: simpleMode ? false : true,
		},
		tooltip: {
			valueDecimals: 2,
			xDateFormat: '%A %d %B %Y %H:%M:%S',
		},
		title: {
			text: `Mesures instantanées ${batiment.nom_bat} - ${sensor.type_energie}`,
			y: 20, // Déplace le titre plus haut que le début du graphique
			style: {
				fontSize: '20px',
			},
			margin: 40,
		},
		xAxis: {
			type: 'datetime',
			labels: {
				rotation: 0, // Désactive la rotation des étiquettes de l'axe X
				style: {
					fontSize: '10px', // Réduit la taille de police des étiquettes de l'axe X
				},
			},
			plotBands: plotbands,
		},
		yAxis: [
			{
				title: {
					text: 'Mesures instantanées (PPM    )',
					align: 'high', // Aligne le titre de l'axe gauche en haut
					textAlign: 'left',
					y: -15, // Déplace le titre plus haut que le début du graphique
					rotation: 0, // Désactive la rotation du titre de l'axe gauche
					style: {
						color: '#628fc9', // Utilise la première couleur de la palette Highcharts,
						fontSize: '13px',
						fontWeight: 'bold',
						width: 250,
					},
				},
				labels: {
					format: `{value}`,
				},
				opposite: false,
			},
		],

		boost: {
			enabled: true,
		},

		series: [
			{
				type: 'column',
				name: 'mesures instantanées',
				data: mesures as (number | [string | Date, number] | PointOptionsObject)[],
				dataGrouping: {
					enabled: false,
				},
				zoneAxis: 'y',
				color: 'purple',
				zones: [
					{
						value: 0,
						color: '#01e500',
						fillColor: '#01e500',
					},
					{
						value: 50,
						color: '#01e500',
						fillColor: '#01e500',
					},
					{
						value: 51,
						color: '#93d150',
						fillColor: '#93d150',
					},
					{
						value: 100,
						color: '#93d150',
						fillColor: '#93d150',
					},
					{
						value: 101,
						color: '#feff01',
						fillColor: '#feff01',
					},
					{
						value: 150,
						color: '#feff01',
						fillColor: '#feff01',
					},
					{
						value: 151,
						color: '#ff7f01',
						fillColor: '#ff7f01',
					},
					{
						value: 200,
						color: '#ff7f01',
						fillColor: '#ff7f01',
					},
					{
						value: 201,
						color: '#fe0100',
						fillColor: '#fe0100',
					},
					{
						value: 250,
						color: '#fe0100',
						fillColor: '#fe0100',
					},
					{
						value: 251,
						color: '#98014c',
						fillColor: '#98014c',
					},
					{
						value: 350,
						color: '#98014c',
						fillColor: '#98014c',
					},
					{
						value: 351,
						color: '#663201',
						fillColor: '#663201',
					},
					{
						value: 1000000000,
						color: '#663201',
						fillColor: '#663201',
					},
				],
				tooltip: {
					valueSuffix: `  ${sensor.unite}`,
				},
			},
		],
	};
}
