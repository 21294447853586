import React, { useRef } from "react";
import "./searchBar.scss"
import { MdClose } from "react-icons/md";
import { FaSearch } from "react-icons/fa";

const SearchBar = (props: any) => {
    const searchInput = useRef<HTMLInputElement>(null)
 
    //vide l'input de recherche
    const handleClear = () => {
        searchInput.current.value = ""
        props.research("")
    }

    return (
        <div className="mySitesSearchBar">
            <span>
                <FaSearch />
            </span>         
            <input type="text" placeholder="Rechercher un site ..." onChange={(e) => props.research(e.target.value)} ref={searchInput}/>
            <button onClick={() => handleClear()}>
                <MdClose/>
            </button>
        </div>
    )
}

export default SearchBar