import React, { useEffect, useRef, useState } from "react";
import Header from "@components/header/Header";
import NavBarLogged from "@components/navBarLogged/NavbarLogged";
import "./dashboardPage.scss";
import Dashboard from "@pages/private/user/dashboardPage/components/dashboard/Dashboard";
import { useDisclosure } from "@chakra-ui/react";
import DashboardDrawer from "@pages/private/user/dashboardPage/components/dashboardDrawer/DashboardDrawer";
import { DashboardContext } from "@hooks/useDashboardContext";
import { batiment, site } from "@tsTypes/mySitesTypes";
import DashboardTitle from "@pages/private/user/dashboardPage/components/dahsboardTitle/DashboardTitle";
import { sendGAPageView } from "@api/analytics";

export default function DashBoardPage() {
    document.title = "kWh50 - Dashboard";

    const { isOpen, onClose, onOpen } = useDisclosure();
    const toggleBtn = useRef<HTMLButtonElement>();

    const [current, setCurrent] = useState<{
        currentSite: site | null;
        currentBat: batiment | null;
        date: Date | null;
    }>({
        currentSite: null,
        currentBat: null,
        date: null,
    });

    useEffect(() => {
        sendGAPageView("Dashboard", window);
    }, []);

    return (
        <div className="dashboard-page">
            <Header title="Dashboard" />
            <NavBarLogged />
            <main>
                <DashboardContext.Provider value={{ current, setCurrent }}>
                    <DashboardTitle
                        onOpen={onOpen}
                        toggleBtn={toggleBtn}
                        title={
                            current?.currentBat !== null
                                ? current.currentBat.nom_bat
                                : "Chargement..."
                        }
                    />
                    <Dashboard />
                    <DashboardDrawer
                        isOpen={isOpen}
                        onClose={onClose}
                        toggleBtn={toggleBtn}
                    />
                </DashboardContext.Provider>
            </main>
        </div>
    );
}
