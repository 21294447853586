import {
    Drawer,
    DrawerBody,
    DrawerCloseButton,
    DrawerContent,
    DrawerHeader,
    DrawerOverlay,
    Select,
    Stack,
} from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from "react";
import fetchApi from "@api/fetchApi";
import { batiment, idBatiment, site } from "@tsTypes/mySitesTypes";
import { DashboardContext } from "@hooks/useDashboardContext";
import { Calendar } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { fr } from "date-fns/locale";
import "./dashboardDrawer.scss";

//fonction pour récupérer les sites de l'utilisateur
async function getSites() {
    const response = await fetchApi.get("/my-sites/get-sites");

    return response.data.data;
}

async function getFirstLastDate(idBat: idBatiment) {
    const response = await fetchApi.post("/dashboard/get-first-last-date", {
        idBat: idBat,
    });
    return response.data;
}

//----------------------------------------------------------------------

export default function DashboardDrawer({
    isOpen,
    onClose,
    toggleBtn,
}: {
    isOpen: boolean;
    onClose: () => void;
    toggleBtn: React.RefObject<HTMLButtonElement>;
}) {
    const [sites, setSites] = useState<site[]>([]);
    const { current, setCurrent } = useContext(DashboardContext);
    const [minMaxDate, setMinMaxDate] = useState<{
        minDate: Date;
        maxDate: Date;
    } | null>({ minDate: new Date(), maxDate: new Date() });

    useEffect(() => {
        //on récupère les sites de l'utilisateur
        getSites().then((_sites) => {
            //on set la liste des sites
            setSites(_sites);
            
            //on récupère les dates de début et de fin de la période
            getFirstLastDate(_sites[0]?.batiments[0]?.id_bat).then(
                (dates: { mindate: number; maxdate: number }) => {
                    console.log(new Date(dates.mindate * 1000));
                    setMinMaxDate({
                        minDate: new Date(dates.mindate * 1000),
                        maxDate: new Date(dates.maxdate * 1000),
                    });
                    
                    //on set le site et le batiment courant au premier site et premier batiment
                    setCurrent({
                        ...current,
                        currentSite: _sites[0],
                        currentBat: _sites[0]?.batiments[0],
                        date: new Date(dates.maxdate * 1000),
                    });
                }
            );
        });
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if(current.currentBat){
            getFirstLastDate(current.currentBat.id_bat).then(
                (dates: { mindate: number; maxdate: number }) => {
                    setMinMaxDate({
                        minDate: new Date(dates.mindate * 1000),
                        maxDate: new Date(dates.maxdate * 1000),
                    });
                }
            );
        }
        // eslint-disable-next-line
    }, [current.currentSite, current.currentBat]);

    return (
        <div className="dashboard-drawer">
            <Drawer
                isOpen={isOpen}
                placement="right"
                onClose={onClose}
                finalFocusRef={toggleBtn}
                size={"md"}
                closeOnEsc={true}
                id="dahsboard-drawer"
            >
                <DrawerOverlay />
                <DrawerContent>
                    <DrawerHeader>Sélectionnez vos dates</DrawerHeader>
                    <DrawerCloseButton />

                    <DrawerBody>
                        <Stack
                            className="stack"
                            spacing={4}
                        >
                            {/* Select du site */}
                            <Select
                                onChange={(event) => {
                                    let newSite = sites.find(
                                        (site: site) =>
                                            site.id_site === event.target.value
                                    );

                                    setCurrent({
                                        ...current,
                                        currentSite: newSite,
                                        currentBat: newSite?.batiments[0],
                                    });
                                }}
                                value={
                                    current?.currentSite?.id_site
                                        ? current.currentSite?.id_site
                                        : ""
                                }
                            >
                                {sites.map((site) => (
                                    <option
                                        key={site.id_site}
                                        value={site.id_site}
                                    >
                                        {site.nom_site}
                                    </option>
                                ))}
                            </Select>

                            {/* Select du batiment */}
                            <Select
                                onChange={(event) =>
                                    setCurrent({
                                        ...current,
                                        currentBat:
                                            current.currentSite?.batiments.find(
                                                (batiment: batiment) =>
                                                    batiment.id_bat ===
                                                    event.target.value
                                            ),
                                    })
                                }
                                value={
                                    current?.currentBat?.id_bat
                                        ? current.currentBat?.id_bat
                                        : ""
                                }
                            >
                                {current.currentSite?.batiments.map(
                                    (batiment) => (
                                        <option
                                            key={batiment.id_bat}
                                            value={batiment.id_bat}
                                        >
                                            {batiment.nom_bat}
                                        </option>
                                    )
                                )}
                            </Select>
                            <div>
                                <Calendar
                                    minDate={minMaxDate.minDate}
                                    maxDate={minMaxDate.maxDate}
                                    onChange={(date) => {
                                        setCurrent({ ...current, date: date });
                                        onClose();
                                    }}
                                    date={current.date}
                                    locale={fr}
                                    color="#225522"
                                    className="calendar"
                                />
                            </div>
                        </Stack>
                    </DrawerBody>
                </DrawerContent>
            </Drawer>
        </div>
    );
}
