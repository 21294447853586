import {
    Button,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    useDisclosure,
} from "@chakra-ui/react";
import React, { useContext, useEffect } from "react";
import { IoIosWarning } from "react-icons/io";
import "./alertDeleteManualMeasure.scss";
import { CalibrationContext } from "@hooks/useCalibrationContext";
import fetchApi from "@api/fetchApi";

const AlertDeleteManualMesure = ({
    setState,
    deletedMeasure,
}: {
    setState: Function;
    deletedMeasure: Highcharts.Point;
}) => {
    //etat courant de la pop-up
    const { isOpen, onOpen, onClose } = useDisclosure();

    const {
        currentSensor,
        manualMeasure,
        setManualMeasure,
        index,
        setIndex,
        realIndex,
        setRealIndex,
        estimateIndex,
        setEstimateIndex
    } = useContext(CalibrationContext);

    //on affiche la pop-up quand le composant est monté
    useEffect(() => {
        //affiche la pop-up
        onOpen();
        //eslint-disable-next-line
    }, []);

    //fonction trigger quand l'user va valider la suppression d'une mesure
    const handleDelete = () => {
        //UTC de la date supprimée
        let UTC = new Date(deletedMeasure.x).getTimezoneOffset() * 60;

        //on formatte le timestamp qui va être à supprimer en bdd
        let resTimeStamp = parseInt(deletedMeasure.x.toString().slice(0, 10));

        //si la mesure est une mesure manuelle
        if (deletedMeasure.series.name === "Mesures manuelles") {
            //on requête le endpoint permettant la suppression
            fetchApi
                .post(
                    `/manual-measure/delete`,
                    {
                        sensorId: currentSensor.id_compteur,
                        timestamp: resTimeStamp + UTC,
                    }
                )
                .then(() => {
                    //on ferme la pop-up
                    onClose();

                    //on met à jour l'état de la pop-up dans le composant parent
                    setState(false);

                    //on supprime la mesure manuelle du state
                    let newManualMeasure = manualMeasure.filter(
                        (measure: any) => {
                            return measure[0] !== deletedMeasure.x;
                        }
                    );

                    //on supprime tous les index supérieur à la nouvelle dernière mesure
                    let newIndex = index.filter((measure: any) => {
                        return (
                            measure[0] <=
                            newManualMeasure[newManualMeasure.length - 1][0]
                        );
                    });

                    //on met à jour l'état de l'index
                    setIndex(newIndex);

                    //on met à jour l'état des mesures manuelles
                    setManualMeasure(newManualMeasure);

                    //suppression du point dans le graphique
                    deletedMeasure.remove();
                });
        } else if (
            deletedMeasure.series.name === "Index de facturation estimé"
        ) {
            //cela signifie que la mesure est un index estimé
            fetchApi
                .post(`/estimate-index/delete`, {
                    sensorId: currentSensor.id_compteur,
                    timestamp: resTimeStamp + UTC,
                })
                .then(() => {
                    //on ferme la pop-up
                    onClose();

                    //on met à jour l'état de la pop-up dans le composant parent
                    setState(false);

                    //on supprime la mesure manuelle du state
                    let newIndex = estimateIndex.filter((measure: any) => {
                        return measure[0] !== deletedMeasure.x;
                    });

                    //on met à jour l'état de l'index
                    setEstimateIndex(newIndex);

                    //suppression du point dans le graphique
                    deletedMeasure.remove();
                });
        } else {
            //cela signifie que la mesure est un index réel
            fetchApi
                .post(`/real-index/delete`, {
                    sensorId: currentSensor.id_compteur,
                    timestamp: resTimeStamp + UTC,
                })
                .then(() => {
                    //on ferme la pop-up
                    onClose();

                    //on met à jour l'état de la pop-up dans le composant parent
                    setState(false);

                    //on supprime la mesure manuelle du state
                    let newIndex = realIndex.filter(
                        (measure: [number, number]) => {
                            return measure[0] !== deletedMeasure.x;
                        }
                    );

                    //on met à jour l'état de l'index
                    setRealIndex(newIndex);

                    //suppression du point dans le graphique
                    deletedMeasure.remove();
                });
        }
    };

    return (
        <Modal
            isOpen={isOpen}
            onClose={() => {
                onClose();
                setState(false);
            }}
            id="alertDeleteManualMesure"
        >
            <ModalOverlay />
            <ModalContent>
                <ModalCloseButton />
                <ModalHeader
                    display="flex"
                    justifyContent="center"
                >
                    <IoIosWarning
                        size="2em"
                        color="red"
                    />
                </ModalHeader>
                <ModalBody textAlign="center">
                    <span>
                        Vous êtes sur le point de supprimer une mesure.
                        Êtes-vous sûr de vouloir continuer ?
                    </span>
                </ModalBody>

                <ModalFooter
                    display="flex"
                    justifyContent="center"
                >
                    <Button
                        id="delete"
                        mr={3}
                        onClick={() => {
                            onClose();
                            handleDelete();
                        }}
                        colorScheme={"red"}
                        isDisabled={manualMeasure[0][0] === deletedMeasure.x}
                    >
                        Supprimer
                    </Button>
                    <Button
                        id="annuler"
                        onClick={() => {
                            onClose();
                            setState(false);
                        }}
                    >
                        Annuler
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

export default AlertDeleteManualMesure;
