import React, { useState } from "react";
import openEye from "@assets/img/icones/open-eye.png"
import closeEye from "@assets/img/icones/close-eye.png"
import "./passwordInput.scss"

const PasswordInput = (props : {passwordInput: any, setCreditentials: Function, creditentials: any}) => {

    const [visibilityInputState, setVisibilityInputState] = useState("password")
    const [iconVisibility, setIconVisibility] = useState(closeEye)

    const handleVisibility = () => {
        if(visibilityInputState === "password") {
            setVisibilityInputState("text")
            setIconVisibility(openEye)
        } else {
            setVisibilityInputState("password")
            setIconVisibility(closeEye)
        }
    }

    return (
        <div className="passwordInput">
            <input type={visibilityInputState} ref={props.passwordInput} name="password" onChange={(e) => props.setCreditentials({...props.creditentials, password: e.target.value})} required/>
            <button onClick={handleVisibility} type="button"><img src={iconVisibility} alt="icone d'oeil" /></button>
        </div>
    )
}

export default PasswordInput