import React, { useContext, useEffect, useState } from "react";
import Highcharts from "highcharts";
import DataGrid from "@highcharts/dashboards/es-modules/masters/datagrid.src";
import Dashboards from "@highcharts/dashboards/es-modules/masters/dashboards.src";
import "@highcharts/dashboards/es-modules/masters/modules/layout.src";
import "./dashboard.scss";
import { Chart } from "./components/Chart";
import { DashboardContext } from "@hooks/useDashboardContext";
import { batiment } from "@tsTypes/mySitesTypes";
import fetchApi from "@api/fetchApi";
import {
    addDays,
    addHours,
    addMonths,
    endOfDay,
    endOfWeek,
    getDaysInMonth,
    lastDayOfMonth,
    lastDayOfWeek,
    startOfDay,
    startOfMonth,
    startOfWeek,
    startOfYear,
} from "date-fns";
import { dashboardDataResult } from "@tsTypes/dashboardType";
import { Spinner } from "@chakra-ui/react";

Dashboards.HighchartsPlugin.custom.connectHighcharts(Highcharts);
Dashboards.DataGridPlugin.custom.connectDataGrid(DataGrid);

Dashboards.PluginHandler.addPlugin(Dashboards.HighchartsPlugin);
Dashboards.PluginHandler.addPlugin(Dashboards.DataGridPlugin);

async function fetchData(date: Date, currentBat: batiment) {
    console.log(date);
    let res = await fetchApi.post("/dashboard/get-datas", {
        idBat: currentBat.id_bat,
        dates: {
            year: getAllBoundsMonth(date),
            month: getAllDaysBoundsInMonth(date),
            week: getAllDaysBoundsInWeek(date),
            day: getAllHoursBoundsInDay(date),
        },
    });

    return res.data;
}

function getAllBoundsMonth(date: Date) {
    let bounds = [];

    let startDate = new Date(startOfYear(date));

    for (let i = 0; i < 12; i++) {
        bounds.push([
            Math.round(startOfMonth(startDate).getTime() / 1000),
            Math.round(lastDayOfMonth(startDate).getTime() / 1000),
        ]);

        startDate = addMonths(startDate, 1);
    }
    return bounds;
}

function getAllDaysBoundsInMonth(date: Date) {
    let bounds = [];

    let startDate = startOfMonth(date);

    for (let i = 0; i < getDaysInMonth(date); i++) {
        bounds.push([
            Math.round(startOfDay(startDate).getTime() / 1000),
            Math.round(endOfDay(startDate).getTime() / 1000),
        ]);

        startDate = addDays(startDate, 1);
    }

    return bounds;
}

function getAllDaysBoundsInWeek(date: Date) {
    let bounds = [];

    let startDate = startOfWeek(date, { weekStartsOn: 1 });

    for (let i = 0; i < 7; i++) {
        bounds.push([
            Math.round(startOfDay(startDate).getTime() / 1000),
            Math.round(endOfDay(startDate).getTime() / 1000),
        ]);

        startDate = addDays(startDate, 1);
    }

    return bounds;
}

function getAllHoursBoundsInDay(date: Date) {
    let bounds = [];

    let startDate = startOfDay(date);

    for (let i = 0; i < 24; i++) {
        bounds.push([
            Math.round(startDate.getTime() / 1000),
            Math.round(addHours(startDate, 1).getTime() / 1000),
        ]);

        startDate = addHours(startDate, 1);
    }

    return bounds;
}

const frenchDate = {
    weekdays: [
        "Dimanche",
        "Lundi",
        "Mardi",
        "Mercredi",
        "Jeudi",
        "Vendredi",
        "Samedi",
    ],
    months: [
        "Janvier",
        "Février",
        "Mars",
        "Avril",
        "Mai",
        "Juin",
        "Juillet",
        "Août",
        "Septembre",
        "Octobre",
        "Novembre",
        "Décembre",
    ],
};

export default function Dashboard() {
    const { current } = useContext(DashboardContext);
    const [datas, setDatas] = useState<dashboardDataResult>(null);
    const [loading, setLoading] = useState<boolean>(true)

    useEffect(() => {
        if (datas !== null) {

            Dashboards.board(
                "dashboard",
                {
                    gui: {
                        layouts: [
                            {
                                id: "layout-1",
                                rows: [
                                    {
                                        cells: [
                                            {
                                                id: "dashboard-col-0",
                                            },
                                            {
                                                id: "dashboard-col-1",
                                            },
                                        ],
                                    },
                                    {
                                        cells: [
                                            {
                                                id: "dashboard-col-2",
                                            },
                                            {
                                                id: "dashboard-col-3",
                                            },
                                        ],
                                    },
                                ],
                            },
                        ],
                    },
                    components: [
                        Chart(
                            "dashboard-col-0",
                            `Consommation annuelle de ${current.date.getFullYear()}`,
                            datas?.year,
                            frenchDate.months
                        ),
                        Chart(
                            "dashboard-col-1",
                            `Consommation mensuelle de ${
                                frenchDate.months[current.date.getMonth()]
                            } ${current.date.getFullYear()}`,
                            datas?.month,
                            Array.from(
                                { length: getDaysInMonth(current.date) },
                                (_, i) => i + 1
                            ).map((i) => i.toString())
                        ),
                        Chart(
                            "dashboard-col-2",
                            `Consommation hebdomadaire du ${startOfWeek(
                                current.date,
                                { weekStartsOn: 1 }
                            ).getDate()} ${
                                frenchDate.months[
                                    startOfWeek(current.date, {
                                        weekStartsOn: 1,
                                    }).getMonth()
                                ]
                            } ${current.date.getFullYear()} au ${lastDayOfWeek(
                                current.date,
                                { weekStartsOn: 1 }
                            ).getDate()} ${
                                frenchDate.months[
                                    endOfWeek(current.date, {
                                        weekStartsOn: 1,
                                    }).getMonth()
                                ]
                            } ${current.date.getFullYear()}`,
                            datas?.week,
                            frenchDate.weekdays
                        ),
                        Chart(
                            "dashboard-col-3",
                            `Consommation journalière du ${current.date.getDate()} ${frenchDate.months[current.date.getMonth()]} ${current.date.getFullYear()}`,
                            datas?.day,
                            Array.from(
                                { length: 24 },
                                (_, i) => i.toString() + " h"
                            )
                        ),
                    ],
                    editMode: {
                        enabled: true,
                        contextMenu: {
                            enabled: true,
                            items: ["editMode"],
                        },
                    },
                },
                true
            );
        }
        // eslint-disable-next-line
    }, [datas]);

    useEffect(() => {
        setLoading(true)
        if (current.currentBat !== null && current.date !== null) {
            fetchData(current.date, current.currentBat).then((res) => {
                setLoading(false)
                setDatas(res);
            });
        }
    }, [current.currentBat, current.date]);

    return (
        <div className="dashboard">
            {datas !== null && loading === false ? (
                <div id="dashboard" />
            ) : (
                <Spinner
                    size="xl"
                    thickness="4px"
                    color="#225522"
                />
            )}
        </div>
    );
}
