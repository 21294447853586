import React, { FormEvent, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./loginPage.scss";
import "animate.css";
import PasswordInput from "@components/passwordInput/PasswordInput";
import { useAlreadyLogged } from "@hooks/useAlreadyLogged";
import { handleLogin } from "@utils/handleLogin";
import { useToast } from "@chakra-ui/react";
import { useLoginStore } from "@store/loginStore";
import NavBar from "@components/navBar/NavBar";
import Footer from "@components/footer/Footer";
import { sendGAPageView } from "@api/analytics";

const Login = () => {

    document.title = "kWh50 - Connexion";

    //hook permettant de vérifier si l'utilisateur est déjà connecté et de le rediriger vers la page mes-sites
    useAlreadyLogged();

    const navigate = useNavigate();

    //variable contenant ce qui est renseigné dans les champs
    const [creditentials, setCreditentials] = useState({
        mail: "",
        password: "",
    });
    const passwordInput = useRef<HTMLInputElement>(null);
    const toast = useToast();
    const { login } = useLoginStore();

    /**
     * @brief Fonction déclanchée lors de la validation du formulaire
     * @param event : FormEvent<HTMLFormElement> --> évennement associé à la validation du formulaire
     */
    const handleForm = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        const { isValid, token, id } = await handleLogin(creditentials);

        if (isValid) {

            //envoie de la connexion sur GA
            sendGAPageView("Page de connexion", window);

            login(token, id);
            navigate("/user/mes-sites");
        } else {
            //on reset la valeur du champ mot de passe
            passwordInput.current.value = "";

            //on affiche le toast signifiant que le mot de passe est incorrecte
            toast({
                title: `Les identifiants saisis sont incorrectes`,
                status: "error",
                isClosable: true,
                position: "top-right",
                id: "errorLogin",
            });
        }
    };

    useEffect(() => {
        sendGAPageView(
            "Page de connexion",
            window
        );
    }, []);

    return (
        <div className="login-page">
            <div className="main">
                <NavBar />
                <main className="login-modal">
                    <h1>Connectez-vous</h1>
                    <form onSubmit={(event) => handleForm(event)}>
                        <article>
                            <label>Saisissez votre email</label>
                            <input
                                type="mail"
                                name="mail"
                                placeholder="ex: test@kwh50.io"
                                onChange={(e) =>
                                    setCreditentials({
                                        ...creditentials,
                                        mail: e.target.value,
                                    })
                                }
                                required
                            />
                        </article>
                        <article>
                            <label>Saisissez votre mot de passe</label>
                            <PasswordInput
                                creditentials={creditentials}
                                setCreditentials={setCreditentials}
                                passwordInput={passwordInput}
                            />
                        </article>
                        <article className="register">
                            <p>
                                Vous n'avez pas encore de compte ?<br />{" "}
                                addressez-vous à{" "}
                                <a href="mailto:Francois.manneville@kwh50.fr">
                                    contact@kwh50.fr.
                                </a>
                            </p>
                        </article>
                        <input
                            type="submit"
                            value="Connexion"
                        />
                    </form>
                </main>
            </div>
            <Footer />
        </div>
    );
};

export default Login;
