import React, { useEffect, useRef } from "react";
import "./welcomePage.scss";
import NavBar from "@components/navBar/NavBar.tsx";
import { Player } from "@lordicon/react";
import chartIcon from "@assets/gif/chart.json";
import computerIcon from "@assets/gif/computer.json";
import batteryIcon from "@assets/gif/battery.json";
import Atropos from "atropos/react";
import Footer from "@components/footer/Footer.tsx";

import Img1_1 from "@assets/img/Layers2/1.png";
import Img1_2 from "@assets/img/Layers2/2.png";
import Img1_3 from "@assets/img/Layers2/3.png";
import Img1_4 from "@assets/img/Layers2/4.png";
import Img1_5 from "@assets/img/Layers2/5.png";

import { useToast } from "@chakra-ui/react";

import { sendGAPageView } from "@api/analytics";

const WelcomePage = () => {

    document.title = "kWh50 - Accueil";

    const chartIconRef = useRef<Player>(null);
    const computerIconRef = useRef<Player>(null);
    const batteryIconRef = useRef<Player>(null);

    const toast = useToast();

    useEffect(() => {
        sendGAPageView(
            "Page d'accueil",
            window)
        

        toast({
            title: "Site en construction",
            description:
                "Nous travaillons actuellement sur notre site web. Merci de votre compréhension.",
            status: "warning",
            duration: 10000,
            isClosable: true,
            position: "bottom-right",
        });
        // eslint-disable-next-line
    }, []);

    return (
        <div id="welcome">
            <NavBar />
            <main>
                <section className="section-1">
                    <div>
                        <h1>
                            <em>
                                Réduisez
                                <svg
                                    role="presentation"
                                    viewBox="-400 -55 730 60"
                                    preserveAspectRatio="none"
                                >
                                    <path
                                        d="m -383.25 -6 c 55.25 -22 130.75 -33.5 293.25 -38 c 54.5 -0.5 195 -2.5 401 15"
                                        stroke="#ffce59"
                                        pathLength="1"
                                        stroke-width="14"
                                        fill="none"
                                    ></path>
                                </svg>
                            </em>{" "}
                            vos consommations énergétiques
                        </h1>
                        <p>
                            kWh50, votre spécialiste de la réduction des
                            consommations énergétiques dans le secteur
                            tertiaire. Réduisez vos consommations & décarbonez
                            vos bâtiments.
                        </p>
                        <a href="/login">Réduire mes consommations</a>
                    </div>
                </section>
                <section className="section-3">
                    <div className="parallax-container">
                        <Atropos
                            className="atropos-card"
                            rotateXMax={5}
                            rotateYMax={5}
                            shadow={false}
                        >
                            <img
                                src={Img1_1}
                                alt=""
                                data-atropos-offset="-10"
                            />
                            <img
                                src={Img1_2}
                                alt=""
                                data-atropos-offset="-8"
                            />
                            <img
                                src={Img1_3}
                                data-atropos-offset="-5"
                                alt=""
                            />
                            <img
                                src={Img1_4}
                                data-atropos-offset="-3"
                                alt=""
                            />
                            <img
                                src={Img1_5}
                                data-atropos-offset="-2"
                                alt=""
                            />
                            <h1 data-atropos-offset="26">
                                Visualisez vos bâtiments
                            </h1>
                            <p data-atropos-offset="18">
                                <b>Visualisez</b> en temps réel vos
                                consommations
                                <br /> directement sur vos bâtiment.
                            </p>
                        </Atropos>
                    </div>
                </section>
                <hr />
                <section className="section-2">
                    <div
                        className="card"
                        onMouseEnter={() => {
                            chartIconRef.current?.playFromBeginning();
                        }}
                    >
                        <div className="card-content">
                            <div className="card-image">
                                <Player
                                    ref={chartIconRef}
                                    icon={chartIcon}
                                    size={64}
                                />
                            </div>
                            <div className="card-header">
                                <h2>Optimisez toutes vos consommations</h2>
                            </div>
                            <div className="card-body">
                                <p>
                                    Nos solutions d’économies d’énergie pour les
                                    bâtiments mettent l’accent sur
                                    l’optimisation et les mesures énergétiques
                                    précises. Grâce à des technologies avancées,
                                    nous identifions les inefficacités et
                                    recommandons des modifications ciblées pour
                                    réduire la consommation d’énergie.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div
                        className="card"
                        onMouseEnter={() => {
                            computerIconRef.current?.playFromBeginning();
                        }}
                    >
                        <div className="card-content">
                            <div className="card-image">
                                <Player
                                    ref={computerIconRef}
                                    icon={computerIcon}
                                    size={64}
                                />
                            </div>
                            <div className="card-header">
                                <h2>Visualisez vos consommations</h2>
                            </div>
                            <div className="card-body">
                                <p>
                                    Notre plateforme vous permet de suivre en
                                    temps réel vos consommations énergétiques.
                                    Vous pouvez visualiser vos données de
                                    consommation d’énergie, de production
                                    d’énergie, de CO2 et d’eau. Vous pouvez
                                    également suivre vos objectifs de réduction
                                    de consommation.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div
                        className="card last-card"
                        onMouseEnter={() => {
                            batteryIconRef.current?.playFromBeginning();
                        }}
                    >
                        <div className="card-content">
                            <div className="card-image">
                                <Player
                                    ref={batteryIconRef}
                                    icon={batteryIcon}
                                    size={64}
                                />
                            </div>
                            <div className="card-header">
                                <h2>Améliorez votre performance énergétique</h2>
                            </div>
                            <div className="card-body">
                                <p>
                                    Notre approche de la performance énergétique
                                    est basée sur l’analyse des données et la
                                    mise en œuvre de solutions durables. Nous
                                    vous aidons à réduire vos coûts énergétiques
                                    et à améliorer votre empreinte carbone.
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            <Footer />
        </div>
    );
};

export default WelcomePage;
