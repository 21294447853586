import React, { useContext, useEffect, useRef, useState } from "react";
import { CalibrationContext } from "@hooks/useCalibrationContext";
import Highcharts, { ChartOptions, SeriesOptionsType } from "highcharts";
import HighchartsBoost from "highcharts/modules/boost";
import HighchartsReact from "highcharts-react-official";
import "./chartCalibration.scss";
import { Link } from "react-router-dom";
import AddManualMeasure from "./components/addManualMeasure/AddManualMeasure";
import AlertDeleteManualMesure from "./components/alertDeleteManualMeasure/AlertDeleteManualMeasure";
import { Button, Spinner } from "@chakra-ui/react";

export default function ChartCalibration(props: any) {
    //on ajoute le module boost à Highcharts
    HighchartsBoost(Highcharts);

    //on récupère les valeurs de l'index et du capteur courant depuis le contexte
    const {
        currentBat,
        currentSensor,
        manualMeasure,
        index,
        date,
        prefixUnit,
        realIndex,
        indexDivider,
        estimateIndex,
    } = useContext(CalibrationContext);

    //on stocke l'état de la pop-up de suppression
    const [isDeletePopUpOpen, setIsDeletePopUpOpen] = useState(false);

    //on stocke la mesure à supprimer
    const [deleteMeasure, setDeleteMeasure] = useState<Highcharts.Point>();

    //on stocke la référence du graphique
    const chartRef = useRef(null);

    //on stocke les options de base du graphique
    const [options] = useState({
        chart: {
            type: "line",
            zoomType: "x",
        },
        legend: {
            itemStyle: {
                fontSize: "16px",
            },
        },
        title: {
            text: `Graphique d'étalonnage : ${currentSensor.description}`,
        },
        xAxis: {
            title: {
                text: "Temps",
            },
            type: "datetime",
        },
        yAxis: {
            title: {
                text: "Index",
            },
            opposite: true,
        },
        series: [
            {
                name: "Index",
                data: [] as any,
                tooltip: {
                    pointFormatter: {},
                },
            },
            {
                name: "Mesures manuelles",
                data: manualMeasure,
                point: {
                    events: {
                        click: function (this: any) {
                            setIsDeletePopUpOpen(true);
                            setDeleteMeasure(this);
                        },
                    },
                },
            },
            {
                name: "Index réel de facturation",
                type: "scatter",
                data: [],
                color: "orange",
                marker: {
                    symbol: "diamond",
                    radius: 5,
                },
                options: {
                    tooltip: {
                        pointFormatter: null,
                    },
                },
                point: {
                    events: {
                        click: function (this: any) {
                            setIsDeletePopUpOpen(true);
                            setDeleteMeasure(this);
                        },
                    },
                },
            },
            {
                name: "Index de facturation estimé",
                type: "scatter",
                data: [],
                color: "purple",
                marker: {
                    symbol: "triangle",
                    radius: 5,
                },
                options: {
                    tooltip: {
                        pointFormatter: null,
                    },
                },
                point: {
                    events: {
                        click: function (this: any) {
                            setIsDeletePopUpOpen(true);
                            setDeleteMeasure(this);
                        },
                    },
                },
            },
        ],
    } as ChartOptions);

    const [chartLoading, setChartLoading] = useState(true);

    //on met à jour le graphique à chaque changement de date ou si l'index est modifié
    useEffect(() => {
        //on récupère le graphique
        const graph: Highcharts.Chart = chartRef.current?.chart;

        if (currentSensor.unite === "Wh") {
            //si le type du capteur est en W
            //on ajoute les données à la série index
            graph?.series[0].setData(index);

            //on ajoute les données à la série mesure manuelle
            graph?.series[1].setData(manualMeasure);

            //on met à jour le titre de l'axe des ordonnées
            graph?.yAxis[0].setTitle({ text: `Index (${prefixUnit}Wh)` });

            //on applique le diviseur des unités vu que l'on est en Wh
            let newRealIndex: [number, number][] = realIndex.map((index) => {
                return [index[0], index[1] / indexDivider];
            });

            //on ajoute les données de l'index réel
            graph?.series[2].setData(newRealIndex);

            //on applique le diviseur des unités vu que l'on est en Wh
            let newEstimateIndex: [number, number][] = estimateIndex.map(
                (index) => {
                    return [index[0], index[1] / indexDivider];
                }
            );

            //on ajoute les données de l'index estimé
            graph?.series[3].setData(newEstimateIndex);

            //on met à jour les tooltips
            //mise à jour du tooltip de la série index
            graph?.series[0].update({
                tooltip: {
                    pointFormatter: function (this: Highcharts.Point): string {
                        return `<br>Index : ${this.y.toFixed(
                            2
                        )} ${prefixUnit}Wh`;
                    },
                },
            } as SeriesOptionsType);

            //mise à jour du tooltip de la série mesure manuelle
            graph?.series[1].update({
                tooltip: {
                    pointFormatter: function (this: Highcharts.Point): string {
                        return `<br>Mesure: ${this.y.toFixed(
                            2
                        )} ${prefixUnit}Wh<br/><br/>(Cliquez pour supprimer)`;
                    },
                },
            } as SeriesOptionsType);

            //on met à jour le tooltip de la série index réel
            graph?.series[2].update({
                tooltip: {
                    pointFormatter: function (this: Highcharts.Point): string {
                        return `<br>Index réel de facturation: ${this.y.toFixed(
                            2
                        )} ${prefixUnit}Wh<br><br>(Cliquer pour supprimer)`;
                    },
                },
            } as SeriesOptionsType);

            //on met à jour le tooltip de la série index estimé
            graph?.series[3].update({
                tooltip: {
                    pointFormatter: function (this: Highcharts.Point): string {
                        return `<br>Index estimé de facturation: ${this.y.toFixed(
                            2
                        )} ${prefixUnit}Wh<br><br>(Cliquer pour supprimer)`;
                    },
                },
            } as SeriesOptionsType);
        } else {
            //si le type du capteur est en m3 c'est donc un compteur de gaz

            //on ajoute les données à la série index
            graph?.series[0].setData(index);
            

            //on change le tooltip de la série index
            graph?.series[0].update({
                tooltip: {
                    pointFormatter: function (this: Highcharts.Point): string {
                        return `<br>Index : ${this.y.toFixed(2)} m3`;
                    },
                },
            } as SeriesOptionsType);

            //on change le tooltip de la série mesure manuelle
            graph?.series[1].update({
                tooltip: {
                    pointFormatter: function (this: Highcharts.Point): string {
                        return `<br>Mesure manuelle: ${this.y.toFixed(
                            2
                        )} m3<br/><br/>(Cliquez pour supprimer)`;
                    },
                },
            } as SeriesOptionsType);

            //on met à jour le titre de l'axe des ordonnées
            graph?.yAxis[0].setTitle({ text: "Index (m3)" });

            //on ajoute les données de relevé manuel
            graph?.series[1].setData(manualMeasure);

            //on ajoute les données de l'index réel
            graph?.series[2].setData(realIndex);

            //on met à jour le tooltip de la série index réel
            graph?.series[2].update({
                tooltip: {
                    pointFormatter: function (this: Highcharts.Point): string {
                        return `<br>Index réel de facturation: ${this.y.toFixed(
                            2
                        )} m3<br/><br/>(Cliquez pour supprimer)`;
                    },
                },
            } as SeriesOptionsType);

            //on ajoute les données de l'index estimé
            graph?.series[3].setData(estimateIndex);

            //on met à jour le tooltip de la série index estimé
            graph?.series[3].update({
                tooltip: {
                    pointFormatter: function (this: Highcharts.Point): string {
                        return `<br>Index estimé de facturation: ${this.y.toFixed(
                            2
                        )} m3<br/><br/>(Cliquez pour supprimer)`;
                    },
                },
            } as SeriesOptionsType);

        }
        
        graph?.redraw();
        setChartLoading(false);
        // eslint-disable-next-line
    }, [index, date.endDate, manualMeasure, realIndex, estimateIndex]);

    return (
        <>
            {isDeletePopUpOpen && (
                <AlertDeleteManualMesure
                    setState={setIsDeletePopUpOpen}
                    deletedMeasure={deleteMeasure}
                />
            )}
            {currentSensor && (
                <div className="chartCalibration">
                    <div className="addSection">
                        <Link
                            to={"/user/mon-batiment"}
                            state={{
                                batiment: currentBat,
                                currentSensor,
                                date: {
                                    startDate: props.dateBack.startDate,
                                    endDate: props.dateBack.endDate,
                                },
                            }}
                        >
                            <Button>Retour</Button>
                        </Link>
                        <div>
                            <AddManualMeasure />
                        </div>
                    </div>
                    {!chartLoading && (
                        <HighchartsReact
                            highcharts={Highcharts}
                            options={options}
                            ref={chartRef}
                        />
                    )}

                    {chartLoading && (
                        <div className="loading">
                            <Spinner />
                        </div>
                    )}
                </div>
            )}
        </>
    );
}
