import { sensor } from "@tsTypes/sensorType";
import React, { useMemo } from "react";
import "./selectMeasure.scss";

interface props {
    data: sensor[];
    setCurrentSensor: (sensor: sensor) => void;
    currentSensor: sensor;
}

export default function SelectMeasure({
    data,
    setCurrentSensor,
    currentSensor,
}: props) {
    //useState qui va contenir uniquement les capteurs ayant l'id du compteur courant
    const sensors = useMemo(() => {
        if (!currentSensor) return;

        if (currentSensor?.sous_compteur) {
            return [currentSensor];
        }

        return data.filter(
            (sensor: sensor) => sensor.id_compteur === currentSensor.id_compteur
        );
    }, [data, currentSensor]);

    return (
        <select
            name="measure"
            id="select-measure"
            onChange={(e) => {
                setCurrentSensor(
                    sensors.find(
                        (sensor: sensor) =>
                            sensor.type_energie === e.target.value
                    )
                );
            }}
        >
            {sensors?.map((sensor: sensor) => (
                <option
                    key={sensor?.type_energie}
                    value={sensor?.type_energie}
                >
                    {sensor?.type_energie}
                </option>
            ))}
        </select>
    );
}
