import { batiment, site } from "@tsTypes/mySitesTypes";
import { highLightResearch } from "./highLightResearch";

/**
 * Fonction qui filtre les sites en fonction de la recherche
 * @param _searchValue : string --> valeur de la recherche
 * @param dataSiteFix : site[] --> liste des sites
 * @returns : Promise<site[]> --> liste des sites filtrés
 */
export async function handleResearch(
    _searchValue: string,
    dataSiteFix: site[]
): Promise<site[]> {
    let result: site[] = dataSiteFix.map((site: site) => ({
        ...site,
        batiments: site.batiments.map((batiment: batiment) => ({
            ...batiment,
        })),
    }));

    _searchValue = _searchValue.trim();

    //eslint-disable-next-line
    result = result.filter((_site: site) => {

        let res: site;

        if (_site.nb_batiments === 1) {
            //on vérifie le nom du seul bâtiment
            if (
                _site.batiments[0].nom_bat.includes(
                    _searchValue.toUpperCase()
                ) === true
            ) {
                _site.batiments[0].nom_bat = highLightResearch(
                    _site.batiments[0].nom_bat,
                    _searchValue
                );

                res = _site;
            }
        } else {
            //si la recherche est présente dans le nom du site
            if (_site.nom_site.includes(_searchValue.toUpperCase())) {
                _site.nom_site = highLightResearch(
                    _site.nom_site,
                    _searchValue
                );

                _site.batiments.map((_batiment: batiment) => {
                    _batiment.nom_bat = highLightResearch(
                        _batiment.nom_bat,
                        _searchValue
                    );

                    return _batiment;
                });

                res = _site;
            } else {
                //on filtre tous les bâtiments du site et on ne garde que ceux qui contiennent la sous chaîne recherchée
                //eslint-disable-next-line
                _site.batiments = _site.batiments.filter(
                    (_batiment: batiment) => {

                        let resBat: batiment;

                        if (
                            _batiment.nom_bat.includes(
                                _searchValue.toUpperCase()
                            ) === true
                        ) {
                            _batiment.nom_bat = highLightResearch(
                                _batiment.nom_bat,
                                _searchValue
                            );

                            resBat = _batiment;
                        }

                        return resBat;
                    }
                );

                //si le site n'a plus aucuns bâtiments on le supprime de la liste
                if (_site.batiments.length !== 0) {
                    res = _site;
                }
            }
        }

        return res;
    });

    return result;
}
