import { AssetRoom } from "@tsTypes/smplrspaceTypes";
import { determineTempTooltipColor } from "@utils/dashboardBatiment";
import React from "react";

interface props {
    d: AssetRoom;
}

export default function Tooltip(d: AssetRoom) {
    const temperature = d.data.find((d) => d.type === "température")?.valeur;
    const humidity = d.data.find((d) => d.type === "humidité")?.valeur;

    return `${d.description}<br/>
            Température: <b style="color: ${determineTempTooltipColor(
                temperature
            )}">${temperature}</b> (idéale: 19°C)
            `;
}
