import React from "react";
import { Link } from "react-router-dom";
import "./footer.scss"

export default function Footer() {
    return (
        <footer className="footer-component">
            <div>
                <div>
                    <h1>N'attendez plus pour décarbonner.</h1>
                </div>
                <div>
                    <div>
                        <h2>kWh50</h2>
                        <p>21 rue Louis de Broglie</p>
                        <p>22300 Lannion</p>
                    </div>
                    <div>
                        <h2>Contact</h2>
                        <p>contact@kwh50.fr</p>
                        <p></p>
                    </div>
                    <div>
                        <h2>Info</h2>
                        <Link to="/">Mentions légales</Link>
                        <p>Politique de cookies</p>
                    </div>
                </div>
            </div>
            <hr />
            <p>
                © 2024 KWH50. Créé avec 💙 par Studio Salé - studiosale.fr -{" "}
                <a href="https://lordicon.com/">Icons by Lordicon.com</a>
            </p>
        </footer>
    );
}
