import React, { useEffect, useRef } from "react";
import NotFoundGif from "@assets/gif/404.svg";
import Logo from "@assets/img/logo/kWh50-Logo_Couleursfond blanc.png";
import "./notFoundPage.scss";
import { Link } from "react-router-dom";
import { sendGAPageView } from "@api/analytics";

export default function NotFoundPage() {
    document.title = "kWh50 - 404";

    const gif = useRef(null);

    useEffect(() => {
        sendGAPageView("Page 404", window);

        window.setTimeout(gif.current.classList.add("animated"), 1000);
    }, []);

    return (
        <main className="not-found">
            <img
                className="logo"
                src={Logo}
                alt="Logo kwh50"
            />
            <section className="content">
                <section className="text">
                    <h1>Oups...</h1>
                    <h2>
                        Il semblerait que la page que vous cherchiez ait été
                        modifiée ou bien n'existe plus.
                    </h2>
                    <p>
                        Pour nous faire pardonner voici quelques liens utiles :
                    </p>
                    <ul>
                        <li>
                            <Link to="/">Accueil</Link>
                        </li>
                        <li>
                            <Link to="/user/mes-sites">Mes sites</Link>
                        </li>
                        <li>
                            <Link to="/user/ma-carte">Ma carte</Link>
                        </li>
                        <li>
                            <Link to="/user/dashboard">
                                Mon tableau de bord
                            </Link>
                        </li>
                    </ul>
                </section>
                <img
                    src={NotFoundGif}
                    alt="404"
                    ref={gif}
                />
            </section>
            <footer>
                <a href="https://storyset.com/people">
                    People illustrations by Storyset
                </a>
            </footer>
        </main>
    );
}
