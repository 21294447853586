import React, { useEffect, useRef, useState } from "react";
import "./myMap.scss";
import fetchApi from "@api/fetchApi";
import { batiment, site } from "@tsTypes/mySitesTypes";
import { Spinner } from "@chakra-ui/react";
import MapBatimentMenu from "@pages/private/user/mapPage/components/mapBatimentsMenu/MapBatimentMenu";
import Map, { MapRef, Marker, Popup } from "react-map-gl"; // eslint-disable-line import/no-webpack-loader-syntax
import { Link } from "react-router-dom";

export default function MyMap() {
    const [batCoords, setBatCoords] = useState<
        { batiment: batiment; coords: [number, number]; site: string }[]
    >([]);

    const mapRef = useRef<MapRef>(null);

    const [coordsPopup, setCoordsPopup] = useState<[number, number]>([0, 0]);
    const [popupData, setPopupData] = useState<{
        batiment: batiment;
        coords: [number, number];
        site: string;
    }>(null);

    useEffect(() => {
        const coords: {
            batiment: batiment;
            coords: [number, number];
            site: string;
        }[] = [];

        fetchApi.get("/my-sites/get-sites").then((response) => {
            response.data.data.forEach((site: site) => {
                site.batiments.forEach((bat: batiment) => {
                    coords.push({
                        batiment: bat,
                        coords: [
                            parseFloat(bat.latitude),
                            parseFloat(bat.longitude),
                        ],
                        site: site.nom_site,
                    });
                });
            });
            setBatCoords(coords);
        });
    }, []);

    return (
        <main className="ma-carte">
            <MapBatimentMenu
                batCoords={batCoords}
                map={mapRef}
            />
            {batCoords.length > 0 ? (
                <>
                    <Map
                        mapboxAccessToken="pk.eyJ1IjoiYXN0cmFmaXJlIiwiYSI6ImNsbHdhcWVsbDFsaXYza283bDg0OGF1NjgifQ.GQrZPcHEtktQ_na3ti4ebg"
                        initialViewState={{
                            longitude: batCoords[0].coords[1],
                            latitude: batCoords[0].coords[0],
                            zoom: 17,
                        }}
                        style={{ width: "100%", height: "100%" }}
                        mapStyle="mapbox://styles/astrafire/cly7bi7u300fv01qpchb7f681"
                        ref={mapRef}
                    >
                        {popupData && (
                            <Popup
                                longitude={coordsPopup[0]}
                                latitude={coordsPopup[1]}
                                onClose={() => setPopupData(null)}
                            >
                                <div className="popup-container">
                                    {popupData.batiment.nom_bat}
                                    <Link
                                        to={"/user/mon-batiment"}
                                        state={{
                                            batiment: popupData.batiment,
                                            nomSite: popupData.site,
                                        }}
                                        className="voir-site"
                                    >
                                        Voir mon site
                                    </Link>
                                </div>
                            </Popup>
                        )}
                        {batCoords.map((bat) => (
                            <Marker
                                key={bat.batiment.id_bat}
                                longitude={bat.coords[1]}
                                latitude={bat.coords[0]}
                                onClick={(e: any) => {
                                    setCoordsPopup([
                                        bat.coords[1],
                                        bat.coords[0],
                                    ]);
                                    e.originalEvent.stopPropagation();
                                    setPopupData(bat);
                                }}
                            />
                        ))}
                    </Map>
                </>
            ) : (
                <Spinner
                    thickness="4px"
                    speed="0.65s"
                    emptyColor="gray.200"
                    color="#225522"
                    size="xl"
                />
            )}
        </main>
    );
}
