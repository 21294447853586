import fetchApi from "@api/fetchApi";
import { idPersonne } from "@tsTypes/personneType";

/**
 * @brief Fonction déclanchée lors de la validation du formulaire
 * @param event : FormEvent<HTMLFormElement> --> évennement associé à la validation du formulaire
 */
export async function handleLogin(creditentials: {
    mail: string;
    password: string;
}): Promise<{ isValid: boolean, token: string, id: idPersonne }> {
    try {
        //On envoi une requête au endPoint login qui créer un cookie contenant le token crypté si les identifiants sont correctes
        const res = await fetchApi.post("/login/login", {
            mail: creditentials.mail,
            mdp: creditentials.password,
        });

        return { isValid: true, token: res.data.token, id: res.data.idpers};
    } catch (_err) {
        return { isValid: false, token: "", id: ""};
    }
}
