import { create } from "zustand";
import { idPersonne } from "@tsTypes/personneType";
import { persist } from "zustand/middleware";
import { sendGAEvent } from "@api/analytics";

type Store = {
    isLogin: boolean,
    id: idPersonne,
    token: string,
    login: (token: string, id: idPersonne) => void,
    logout: () => void,
};

export const useLoginStore = create<Store>()(
    persist(
        (set) => ({
            isLogin: false,
            id: "",
            token: "",
            login: (token: string, id: idPersonne) => {
                set({ isLogin: true, id: id, token: token })
                sendGAEvent("Login", "Login", id);
            },
            logout: () => {

                const { id } = useLoginStore.getState();

                sendGAEvent("Logout", "logout", id);
                set({ isLogin: false, id: "", token: "" });
                useLoginStore.persist.clearStorage();
            },
        }),
        {
            name: "login-storage",
            getStorage: () => sessionStorage,
        }
    )
);