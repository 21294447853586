import { createContext } from "react";
import { sensor} from "@tsTypes/sensorType";
import { batiment } from "@tsTypes/mySitesTypes";

export const CalibrationContext = createContext<{
    currentSensor: sensor | null,
    setCurrentSensor: Function,
    currentBat: batiment | null,
    currentTab: sensor | null,
    setCurrentTab: Function,
    dataPiece: sensor[] | null,
    dataSensor: sensor[] | null,
    date: {startDate: Date, endDate: Date},
    setDate: Function,
    manualMeasure: [number, number][] | null,
    setManualMeasure: Function,
    index: [number, number][] | null,
    setIndex : Function,
    indexDivider: number,
    prefixUnit: string,
    realIndex: [number, number][],
    setRealIndex: Function,
    estimateIndex: [number, number][],
    setEstimateIndex: Function
}>({
    currentSensor: null,
    currentBat: null,
    currentTab: null,
    dataPiece: null,
    dataSensor: null,
    date: {startDate: new Date(), endDate: new Date()},
    setDate: () => {},
    setCurrentTab: () => {},
    setCurrentSensor: () => {},
    manualMeasure: [],
    setManualMeasure: () => {},
    index: [],
    setIndex: () => {},
    indexDivider: 1,
    prefixUnit: "",
    realIndex: [],
    setRealIndex: () => {},
    estimateIndex: [],
    setEstimateIndex: () => {}
})