import React, { MutableRefObject } from "react";
import "./mapBatimentMenu.scss";
import { batiment } from "@tsTypes/mySitesTypes";
import MapBatimentMenuItem from "./components/mapBatimentMenuItem/MapBatimentMenuItem";
import {
    Accordion,
    AccordionButton,
    AccordionIcon,
    AccordionItem,
    AccordionPanel,
    Box,
} from "@chakra-ui/react";
import { MapRef } from "react-map-gl";

export default function MapBatimentMenu({
    batCoords,
    map,
}: {
    batCoords: { batiment: batiment; coords: [number, number]; site: string }[];
    map: MutableRefObject<MapRef> | null;
}) {
    return (
        <Accordion
            allowToggle
            className="map-batiment-menu"
        >
            <AccordionItem>
                <h2>
                    <AccordionButton _expanded={{borderBottomRadius: "0px !important"}}>
                        <Box
                            as="span"
                            flex="1"
                            textAlign="left"
                        >
                            Mes batiments
                        </Box>
                        <AccordionIcon />
                    </AccordionButton>
                </h2>
                <AccordionPanel className="panel" pb={4}>
                    {batCoords.map((bat, index) => {
                        return (
                            <div key={index}>
                                <MapBatimentMenuItem
                                    map={map}
                                    batiment={bat.batiment}
                                    nomSite={bat.site}
                                />
                                <hr />
                            </div>
                        );
                    })}
                </AccordionPanel>
            </AccordionItem>
        </Accordion>
    );
}
