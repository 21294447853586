import { createContext } from "react";
import { batiment, site } from "@tsTypes/mySitesTypes";

export const DashboardContext = createContext<{
    current: {
        currentSite: site | null;
        currentBat: batiment | null;
        date: Date | null;
    },
    setCurrent: Function
}>({
    current: {
        currentSite: null,
        currentBat: null,
        date: new Date()
    },
    setCurrent: () => {}
})